import { Component, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import ErrorBoundary from "./ErrorBoundry";
import Icon from "./Icon";
import Button from "./Button";

interface ConfirmationPopupProps {
  title: string;
  message: string;
  confirmText: string;
  onConfirm: () => void;
  cancelText?: string;
  onCancel: () => void;
}

class ConfirmationPopup extends Component<ConfirmationPopupProps> {
  render() {
    const { title, message, cancelText, onCancel, confirmText, onConfirm } =
      this.props;

    return (
      <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            <Transition
              show={true}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              key={Date.now().toString(36)}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Icon
                        name="information-circle"
                        className="h-6 w-6 text-blue-500"
                        aria-hidden="true"
                      />
                    </div>
                    <ErrorBoundary type="confirmation">
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          {title}
                        </p>
                        <p className="mt-1 text-justify text-sm text-gray-500">
                          {message}
                        </p>
                      </div>
                    </ErrorBoundary>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={onCancel}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-end gap-4 mt-4">
                    {cancelText ? (
                      <button
                        onClick={onCancel}
                        className="text-sm text-blue-500 hover:underline"
                      >
                        {cancelText}
                      </button>
                    ) : (
                      <></>
                    )}
                    <Button name={confirmText} onClick={onConfirm} />
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>
    );
  }
}

export default ConfirmationPopup;
