import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { ErrorRow } from ".";

interface ErrorModalProps {
  showModal: boolean;
  hideModal: () => void;
  errorData: ErrorRow[];
}

const ErrorModal = ({ showModal, hideModal, errorData }: ErrorModalProps) => {
  const [collapsedFields, setCollapsedFields] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    const collapsedFields: Record<string, boolean> = {};
    errorData.forEach((errorRow, index) => {
      collapsedFields[errorRow.error] = index === 0 ? false : true;
    });
    setCollapsedFields(collapsedFields);
  }, [errorData]);

  const handleExpandCollapse = (errorRow: ErrorRow) => () => {
    setCollapsedFields({
      ...collapsedFields,
      [errorRow.error]: !collapsedFields[errorRow.error]
    });
  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-[60]" onClose={hideModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={hideModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-xl leading-6 font-bold text-gray-900"
                  >
                    Import Errors
                  </Dialog.Title>
                  <p className="text-xs text-red-700 my-3">
                    * A file consisting errors cannot be imported. Please fix
                    the errors and try again.
                  </p>
                </div>
                <div className="mt-2 divide-y-2 divide-gray-400">
                  {errorData.map(errorRow => (
                    <div
                      key={errorRow.error}
                      className="mt-2 text-center sm:text-left"
                    >
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-800 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 w-full flex gap-4 items-center justify-between pb-2"
                        onClick={handleExpandCollapse(errorRow)}
                      >
                        <span className="text-lg font-medium mt-3">
                          {errorRow.error}
                        </span>
                        <ChevronDownIcon
                          className={`h-5 w-5 transform ${
                            collapsedFields[errorRow.error] ? "" : "rotate-180"
                          }`}
                          aria-hidden="true"
                        />
                      </button>
                      {!collapsedFields[errorRow.error] && (
                        <div className="my-3 flex gap-6 flex-wrap">
                          {errorRow.rows.map((error, index) => (
                            <p
                              key={`${errorRow.error}-${index}-${error.rowNumber}`}
                              className="whitespace-nowrap text-base"
                            >
                              {error.rowNumber}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                    onClick={hideModal}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ErrorModal;
