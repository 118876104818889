export const adminRights = {
  clientRights: { export: true },
  userRights: { create: true, edit: true, delete: true }
} as const;

export const otherRights = {
  clientRights: { export: false },
  userRights: { create: false, edit: false, delete: false }
} as const;

export type AdminRights = typeof adminRights;
export type OtherRights = typeof otherRights;

export type UserRights = AdminRights | OtherRights;

export type ClientRightsName = keyof UserRights["clientRights"];
export type UserRightsName = keyof UserRights["userRights"];
