import { ChangeEvent, Component, ComponentType, FormEvent } from "react";
import { WithRouterProps, withRouter } from "../../helpers/withRouter";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import Dashboard from "../../components/Dashboard";
import { RootState, AppDispatch } from "../../store";
import { CommonState } from "../../store/reducers/common";
import { NotificationType } from "../../store/reducers/notification";
import { ADD_NOTIFICATION } from "../../store/types";
import { ClientDetailsResponse, PageTypeParams } from "../../helpers/types";
import agent from "../../agent";
import capitalize from "../../helpers/capitalize";
import validateGSTIN from "../../helpers/GSTValidationFunction";
import {
  validEmail,
  validMobile,
  validName,
  validPAN,
  validTAN,
  validate,
} from "../../helpers/regex";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import Icon from "../../components/Icon";
import { Tab } from "@headlessui/react";
import {
  ClientRightsName,
  UserRights,
} from "../../constants/defaultUserRights";

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } }),
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: "UPDATE_COMMON", payload }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const fields = {
  "Basic Details": [
    { name: "File no.", fieldName: "fileNo" },
    { name: "Name", fieldName: "name" },
    { name: "Trade Name", fieldName: "tradeName" },
    { name: "Contact Person Name", fieldName: "contactPersonName" },
    { name: "Mobile", fieldName: "mobile" },
    { name: "Email", fieldName: "email" },
  ],
  "GST Details": [
    { name: "GSTIN", fieldName: "gstin" },
    { name: "GST Username", fieldName: "gstUsername" },
    { name: "GST Password", fieldName: "gstPassword" },
    { name: "E-Way Bill Username", fieldName: "eWayBillUsername" },
    { name: "E-Way Bill Password", fieldName: "eWayPassword" },
    { name: "E-Invoice Username", fieldName: "einvoicingUsername" },
    { name: "E-Invoice Password", fieldName: "einvoicingPassword" },
  ],
  "Income Tax Details": [
    { name: "PAN", fieldName: "pan" },
    { name: "IT Portal Password", fieldName: "itPortalPassword" },
    { name: "TAN", fieldName: "tan" },
    { name: "TAN Password", fieldName: "tanPassword" },
    { name: "Traces Username", fieldName: "tracesUsername" },
    { name: "Traces Password", fieldName: "tracesPassword" },
    {
      name: "Traces Tax Payer Password",
      fieldName: "tracesTaxPayerPassword",
    },
  ],
  Other: [
    { name: "MCA V2 Username", fieldName: "mcaV2Username" },
    { name: "MCA V2 Password", fieldName: "mcaV2Password" },
    { name: "MCA V3 Username", fieldName: "mcaV3Username" },
    { name: "MCA V3 Password", fieldName: "mcaV3Password" },
    { name: "DGFT Username", fieldName: "dgftUsername" },
    { name: "DGFT Password", fieldName: "dgftPassword" },
  ],
} as const;

type Props = Partial<PropsFromRedux & WithRouterProps<PageTypeParams>> & {};

const initialClientDetails = {
  fileNo: "",
  name: "",
  tradeName: "",
  contactPersonName: "",
  mobile: "",
  email: "",
  gstin: "",
  gstUsername: "",
  gstPassword: "",
  eWayBillUsername: "",
  eWayPassword: "",
  einvoicingUsername: "",
  einvoicingPassword: "",
  pan: "",
  itPortalPassword: "",
  tan: "",
  tanPassword: "",
  tracesUsername: "",
  tracesPassword: "",
  tracesTaxPayerPassword: "",
  mcaV2Username: "",
  mcaV2Password: "",
  mcaV3Username: "",
  mcaV3Password: "",
  dgftUsername: "",
  dgftPassword: "",
};

export type ClientDetails = typeof initialClientDetails;

export type ClientFieldName = keyof ClientDetails;

type State = {
  loading: boolean;
  clientDetails: ClientDetails;
  showPassword: ClientFieldName[];
};

class AddEditPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      clientDetails: props.selectedClient
        ? (Object.keys(initialClientDetails) as ClientFieldName[]).reduce(
            (acc, key) => {
              acc[key] = props.selectedClient?.[key] || "";
              return acc;
            },
            {} as ClientDetails,
          )
        : initialClientDetails,
      showPassword: [],
    };
  }

  noRightNotification = (type: `${ClientRightsName} clients`) => {
    this.props.addNotification?.(
      "Rights Not Available",
      `You do not have rights to ${type}`,
      "warn",
    );
  };

  pageType = this.props.params?.pageType;

  getClientId = () => {
    const clientId = this.props.searchParams?.get("clientId");
    return clientId?.length === 24 ? clientId : null;
  };

  componentDidMount() {
    document.title = `Client ${capitalize(this.pageType)} - Finexo Login Tool`;

    if (
      this.pageType === "edit" &&
      this.getClientId() &&
      !this.props.selectedClient
    ) {
      this.getClientDetails();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.pageType === "edit" && !this.getClientId()) {
      this.props.navigate?.(`/${this.props.params?.firmId}/clients/list`);
      this.props.addNotification?.(
        "Client Id is not valid",
        "Could not fetch client details",
        "danger",
      );
    }

    if (
      prevProps.params?.pageType !== this.props.params?.pageType &&
      this.props.params?.pageType === "edit" &&
      this.getClientId() &&
      prevProps.selectedClient !== this.props.selectedClient &&
      !this.props.selectedClient
    ) {
      this.getClientDetails();
    }
  }

  getClientDetails = () => {
    const workSpaceId = this.props.params?.firmId;
    const clientId = this.getClientId();

    if (workSpaceId && clientId) {
      this.setState({ loading: true });
      agent.Clients.getClient<{ client: ClientDetailsResponse[] }>(
        workSpaceId,
        clientId,
      )
        .then((res) => {
          const client = res.client[0];
          this.setState({
            clientDetails: {
              fileNo: client.fileNo || "",
              name: client.name || "",
              tradeName: client.tradeName || "",
              contactPersonName: client.contactPersonName || "",
              mobile: client.mobile || "",
              email: client.email || "",
              gstin: client.gstin || "",
              gstUsername: client.gstUsername || "",
              gstPassword: client.gstPassword || "",
              eWayBillUsername: client.eWayBillUsername || "",
              eWayPassword: client.eWayPassword || "",
              einvoicingUsername: client.einvoicingUsername || "",
              einvoicingPassword: client.einvoicingPassword || "",
              pan: client.pan || "",
              itPortalPassword: client.itPortalPassword || "",
              tan: client.tan || "",
              tanPassword: client.tanPassword || "",
              tracesUsername: client.tracesUsername || "",
              tracesPassword: client.tracesPassword || "",
              tracesTaxPayerPassword: client.tracesTaxPayerPassword || "",
              mcaV2Username: client.mcaV2Username || "",
              mcaV2Password: client.mcaV2Password || "",
              mcaV3Username: client.mcaV3Username || "",
              mcaV3Password: client.mcaV3Password || "",
              dgftUsername: client.dgftUsername || "",
              dgftPassword: client.dgftPassword || "",
            },
          });
        })
        .catch((err) => {
          this.props.addNotification?.(
            "Could not load Client Details",
            typeof err?.response?.data?.message === "object"
              ? "Could not load Client Details"
              : err?.response?.data?.message || err?.error || err,
            "danger",
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  handleFormUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as ClientFieldName;
    const value = e.target.value;

    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        [fieldName]:
          fieldName === "pan" || fieldName === "tan"
            ? value.toUpperCase()
            : value,
      },
    });
  };

  handleValidation = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const fieldName = e.target.name as ClientFieldName;
      const value = e.target.value;

      switch (fieldName) {
        case "gstin":
          if (value && !validateGSTIN(value)) throw Error("GSTIN is not valid");
          return;
        case "mobile":
          if (!validate(validMobile, value)) throw Error("Mobile is not valid");
          return;
        case "email":
          if (!validate(validEmail, value)) throw Error("Email is not valid");
          return;
        case "pan":
          if (!validate(validPAN, value)) throw Error("Pan is not valid");
          return;
        case "tan":
          if (!validate(validTAN, value)) throw Error("Tan is not valid");
          return;
        case "name":
          if (!value) throw Error("Name is required");

          // if (!validate(validName, value, true))
          //   throw Error("Name is not valid");

          this.setState({
            clientDetails: {
              ...this.state.clientDetails,
              [fieldName]: capitalize(value),
            },
          });
          return;
        case "tradeName":
        case "contactPersonName":
          this.setState({
            clientDetails: {
              ...this.state.clientDetails,
              [fieldName]: capitalize(value),
            },
          });
          return;
        case "gstUsername":
        case "eWayBillUsername":
        case "einvoicingUsername":
        case "tracesUsername":
        case "mcaV2Username":
        case "mcaV3Username":
        case "dgftUsername":
        case "fileNo":
        case "gstPassword":
        case "eWayPassword":
        case "einvoicingPassword":
        case "itPortalPassword":
        case "tanPassword":
        case "tracesPassword":
        case "tracesTaxPayerPassword":
        case "mcaV2Password":
        case "mcaV3Password":
        case "dgftPassword":
        default:
          return;
      }
    } catch (error) {
      const message =
        error instanceof Error
          ? error.message
          : error instanceof Object
          ? error.toString()
          : (error as string);

      this.props.addNotification?.(
        "Please enter valid details",
        message,
        "danger",
      );
    }
  };

  handleShowPassword = (fieldName: ClientFieldName) => {
    const { showPassword } = this.state;
    if (showPassword.includes(fieldName)) {
      this.setState({
        showPassword: showPassword.filter((f) => f !== fieldName),
      });
    } else {
      this.setState({ showPassword: [...showPassword, fieldName] });
    }
  };

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.pageType === "edit" ? this.handleClientEdit() : this.handleClientAdd();
  };

  handleReset = () => {
    this.setState({ clientDetails: initialClientDetails });
  };

  handleClientAdd = () => {
    const { clientDetails } = this.state;
    const workSpaceId = this.props.params?.firmId;
    if (workSpaceId) {
      this.setState({ loading: true });

      agent.Clients.addClient({ ...clientDetails, workSpaceId })
        .then((res) => {
          this.props.addNotification?.(
            "Client added successfully",
            "Client added successfully",
            "success",
          );
          this.props.navigate?.(`/${workSpaceId}/clients/list`);
        })
        .catch((err) => {
          this.props.addNotification?.(
            "Could not add client",
            typeof err?.response?.data?.message === "object"
              ? "Could not add client"
              : err?.response?.data?.message || err?.error || err,
            "danger",
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  handleClientEdit = () => {
    const { clientDetails } = this.state;
    const workSpaceId = this.props.params?.firmId;
    const clientId = this.getClientId();
    if (workSpaceId && clientId) {
      this.setState({ loading: true });

      agent.Clients.editClient({ ...clientDetails, clientId, workSpaceId })
        .then((res) => {
          this.props.updateCommon?.({ selectedClient: undefined });
          this.props.addNotification?.(
            "Client updated successfully",
            "Client updated successfully",
            "success",
          );
          this.props.navigate?.(`/${workSpaceId}/clients/list`);
        })
        .catch((err) => {
          this.props.addNotification?.(
            "Could not update client",
            typeof err?.response?.data?.message === "object"
              ? "Could not update client"
              : err?.response?.data?.message || err?.error || err,
            "danger",
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const ShowPasswordButton = ({
      fieldName,
    }: {
      fieldName: ClientFieldName;
    }) => {
      return (
        <button
          key={fieldName}
          type="button"
          className="absolute right-2 z-10"
          onClick={() => this.handleShowPassword(fieldName)}
        >
          {this.state.showPassword.includes(fieldName) ? (
            <EyeIcon className="w-5" />
          ) : (
            <EyeSlashIcon className="w-5" />
          )}
        </button>
      );
    };

    return (
      <Dashboard>
        <form
          onSubmit={this.handleSubmit}
          onReset={this.handleReset}
          className="max-w-7xl mx-auto px-4 sm:p-6 md:px-8 bg-white rounded-lg shadow py-5"
        >
          <Tab.Group>
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0 flex gap-4 items-center justify-between">
                <h1 className="text-xl font-medium leading-6 text-gray-900 capitalize">
                  {this.pageType} Client
                </h1>
                <div className="flex gap-4 items-center justify-end">
                  <button
                    type="submit"
                    className="px-4 w-fit inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:text-sm disabled:bg-indigo-400 whitespace-nowrap"
                    disabled={this.state.loading}
                  >
                    <span className="w-full flex justify-end">
                      {this.state.loading ? (
                        <Icon name="loading" className="mr-2 w-4 h-4" />
                      ) : (
                        <Icon name="add" className="mr-2 w-4 h-4" />
                      )}
                    </span>
                    {this.pageType === "edit"
                      ? "Update Client"
                      : this.pageType === "add"
                      ? "Add Client"
                      : "Save Client"}
                    <span className="w-full"></span>
                  </button>
                  <button
                    type="reset"
                    onClick={() => {
                      this.props.navigate?.(
                        `/${this.props.params?.firmId}/clients/list`,
                      );
                    }}
                    className="w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-6 py-4 space-y-6">
              <Tab.List as="nav" aria-label="Progress">
                <ol className="w-full border-b -mb-px rounded-md flex gap-4">
                  {Object.keys(fields).map((heading, index) => (
                    <Tab
                      key={heading}
                      as="li"
                      className={({ selected }) =>
                        `${
                          selected
                            ? "text-indigo-600 border-indigo-600"
                            : "text-gray-900 border-transparent"
                        } w-full flex-1 flex items-center justify-center text-sm font-medium py-4 lg:py-3 lg:px-6 px-1 text-center border-b-2 text-gray-500 hover:text-indigo-500 hover:border-indigo-500 whitespace-nowrap`
                      }
                    >
                      {heading}
                    </Tab>
                  ))}
                </ol>
              </Tab.List>
              <Tab.Panels>
                {Object.entries(fields).map(([heading, fields], i) => (
                  <Tab.Panel
                    key={i}
                    as="div"
                    className="sm:divide-y-2 divide-gray-200"
                  >
                    {fields.map(({ name, fieldName }) => (
                      <div
                        key={fieldName}
                        className="py-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4"
                      >
                        <label
                          htmlFor={fieldName}
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          {name}{" "}
                          {fieldName === "name" && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <div className="relative flex-1 flex items-center">
                            <input
                              type={
                                fieldName === "email"
                                  ? "email"
                                  : fieldName === "mobile"
                                  ? "tel"
                                  : fieldName.toLowerCase().includes("password")
                                  ? this.state.showPassword.includes(fieldName)
                                    ? "text"
                                    : "password"
                                  : "text"
                              }
                              required={fieldName === "name"}
                              maxLength={
                                fieldName === "mobile" ? 10 : undefined
                              }
                              id={fieldName}
                              name={fieldName}
                              placeholder={`Enter ${name}`}
                              value={this.state.clientDetails[fieldName]}
                              onChange={this.handleFormUpdate}
                              onBlur={this.handleValidation}
                              pattern={
                                fieldName === "email"
                                  ? validEmail.source
                                  : fieldName === "mobile"
                                  ? validMobile.source
                                  : fieldName === "pan"
                                  ? validPAN.source
                                  : fieldName === "tan"
                                  ? validTAN.source
                                  : undefined
                              }
                              title={
                                fieldName === "email"
                                  ? "Enter a valid email in the format: name@example.com"
                                  : fieldName === "mobile"
                                  ? "Enter a valid mobile number with 10 digits and starting with 6, 7, 8 or 9"
                                  : fieldName === "pan"
                                  ? "Enter a valid PAN in the format: ABCDE1234F"
                                  : fieldName === "tan"
                                  ? "Enter a valid TAN in the format: ABCD12345E"
                                  : undefined
                              }
                              autoComplete="new-password"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                            />
                            {fieldName.toLowerCase().includes("password") && (
                              <ShowPasswordButton fieldName={fieldName} />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* {heading === "Other" && ( */}
                    <p className="text-sm pt-6">
                      <span className="font-bold">Note - </span>
                      <span className="italic">
                        Quick Login from extension is available only on GST,
                        Income Tax and Traces portal. We just provide the option
                        for e-way bill, e-invoice, MCA, DGFT portals, so our
                        users can have all the login information in a single
                        place
                      </span>
                    </p>
                    {/* )} */}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </div>
          </Tab.Group>
        </form>
      </Dashboard>
    );
  }
}

export default compose<ComponentType<Props>>(
  connector,
  withRouter,
)(AddEditPage);
