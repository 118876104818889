// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import agent from "./agent";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async (): Promise<void> => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY as string
    });

    const device = navigator.userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i
    )
      ? "mobile"
      : "computer";
    const browser = navigator.userAgent.match(/Chrome/i)
      ? "chrome"
      : navigator.userAgent.match(/Firefox/i)
      ? "firefox"
      : navigator.userAgent.match(/Safari/i)
      ? "safari"
      : navigator.userAgent.match(/Opera/i)
      ? "opera"
      : navigator.userAgent.match(/MSIE/i)
      ? "internet explorer"
      : "unknown";

    const isTokenSentToServer = window.localStorage.getItem("sentToServer");

    if (currentToken) {
      // console.log("current token for client: ", currentToken);
      isTokenSentToServer !== "1" &&
        sendTokenToServer(currentToken, device, browser);
    } else {
      // console.log(
      //   "No registration token available. Request permission to generate one."
      // );
      initNotification();
    }
  } catch (err) {
    // console.log("An error occurred while retrieving token. ", err);
  }
};

const sendTokenToServer = (token: string, device: string, browser: string) => {
  agent.User.addFirebaseToken(token, "fcm", browser, device)
    .then((res: any) => {
      // console.log("res", res);
      window.localStorage.setItem("sentToServer", "1");
    })
    .catch((err: any) => {
      // console.log("err", err?.response?.data?.message);
    });
};

export function initNotification() {
  if (
    localStorage.getItem("notification") === "granted" ||
    localStorage.getItem("notification") === "default" ||
    localStorage.getItem("notification") === "denied"
  ) {
    return;
  }
  askForNotificationPermission();
}

export function askForNotificationPermission() {
  Notification.requestPermission().then(permission => {
    if (permission === "granted") {
      // console.log("Notification permission granted.");
      new window.Notification("Notification permission granted.", {
        body: "You will get notified about your tasks."
      });
      localStorage.setItem("notification", "granted");
      requestForToken();
    } else if (permission === "denied") {
      // console.log("Permission denied.");
      localStorage.setItem("notification", "denied");
    } else {
      // console.log("Permission wasn't granted.");
      localStorage.setItem("notification", "default");
    }
  });
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.

onMessage(messaging, payload => {
  const notification = new window.Notification(payload?.data?.title as string, {
    body: payload?.data?.body,
    requireInteraction: true
  });
  // console.log("foreground payload", payload);
  notification.onclick = () => {
    payload?.data?.click_action
      ? window.open(payload?.data?.click_action)
      : window.focus();
    notification.close();
  };
});
