import { LOGIN, LOGOUT, TOKEN_PRESENT } from "../types";

const authState = {
  isAuthenticated: false,
  token: ""
};

export type IUser = typeof authState;
export type UserAction = {
  type: typeof LOGIN | typeof LOGOUT | typeof TOKEN_PRESENT;
  payload?: Partial<IUser>;
};

export default function authReducer(state = authState, action: UserAction) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false
      };
    case TOKEN_PRESENT:
      const token = action.payload?.token;
      if (token === "PRESENT")
        return {
          ...state,
          isAuthenticated: true
        };
      else {
        return state;
      }
    default:
      return state;
  }
}
