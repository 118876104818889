export default function RecommendationText() {
  return (
    <>
      <div className="pointer-events-none fixed md:hidden inset-x-0 bottom-0 z-10 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
        <div className="pointer-events-auto flex items-center justify-center gap-x-6 bg-gray-900 py-2.5 px-6 sm:rounded-xl sm:py-3 sm:pr-3.5 sm:pl-4">
          <p className="text-sm leading-6 text-white">
            💡 Use on Desktop for Best Experience
          </p>
        </div>
      </div>
    </>
  );
}
