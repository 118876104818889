import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { ConnectedProps, connect } from "react-redux";
import agent from "../agent";
import { WithRouterProps } from "../helpers/withRouter";
import { AppDispatch, RootState } from "../store";
import { ADD_NOTIFICATION } from "../store/types";
import Icon from "./Icon";
import { NotificationType } from "../store/reducers/notification";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {
  onLoad: () => void;
  inActiveModalSetOpen: (open: boolean) => void;
  state: {
    showInActiveModal: boolean;
    selectedRow: { _id?: string; name?: string };
  };
  type: "client" | "user" | "firm";
};

type State = {
  loading: boolean;
};

class InActiveModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  myRef = React.createRef<HTMLButtonElement>();

  onKeyUpFunction = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      this.setOpen(false);
    }

    if (event.key === "Enter") {
      this.inActiveRow();
    }
  };

  componentDidMount() {
    this.myRef.current!.focus();
    document.addEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  makeClientInactive = () => {
    const workSpaceId = this.props?.currentFirm?._id;
    const clientId = this.props.state.selectedRow._id;
    if (clientId !== undefined) {
      this.setState({ loading: true });
      workSpaceId &&
        agent.Clients.makeClientInactive(workSpaceId, clientId)
          .then(response => {
            this.props.addNotification?.(
              "Client Inactive",
              "Successfully Inactivated a client.",
              "success"
            );
            this.setState({ loading: false });
            this.setOpen(false);
            this.onLoad();
          })
          .catch(err => {
            this.setState({ loading: false });
            this.props.addNotification?.(
              "Error",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          });
    }
  };

  makeUserInactive = () => {
    const workSpaceId = this.props?.currentFirm?._id;
    const userId = this.props.state.selectedRow._id;
    if (userId !== undefined) {
      this.setState({ loading: true });
      workSpaceId &&
        agent.User.makeUserInactive(workSpaceId, userId)
          .then(response => {
            this.props.addNotification?.(
              "User Inactive",
              "Successfully Inactivated a user.",
              "success"
            );
            this.setState({ loading: false });
            this.setOpen(false);
            this.onLoad();
          })
          .catch(err => {
            this.setState({ loading: false });
            this.props.addNotification?.(
              "Error",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          });
    }
  };

  inActiveRow = () => {
    switch (this.props.type) {
      case "client":
        return this.makeClientInactive();
      case "user":
        return this.makeUserInactive();
      default:
        return;
    }
  };

  setOpen = (open: boolean) => {
    this.props.inActiveModalSetOpen(open);
  };

  onLoad = () => {
    this.props.onLoad();
  };

  render() {
    return (
      <>
        <Transition.Root
          show={this.props.state.showInActiveModal}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={undefined}
            onClose={() => null}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button
                        id={"close"}
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => this.setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Mark Inactive
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to mark {this.props.type} '
                            {this.props.state.selectedRow.name}' as inactive?
                          </p>
                          {this.props.type === "firm" && (
                            <p className="text-red-600 mt-2 text-sm font-medium">
                              Note: All the pending invitations sent to the user
                              will be canceled once the firm is marked inactive
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                        onClick={() => this.setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        ref={this.myRef}
                        disabled={this.state.loading}
                        className={
                          "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:mt-0 sm:w-40 sm:text-sm"
                        }
                        onClick={this.inActiveRow}
                      >
                        <span className="w-full flex justify-end">
                          {this.state.loading ? (
                            <Icon name="loading" className="mr-1.5" />
                          ) : null}
                        </span>
                        <span className="whitespace-nowrap">Mark Inactive</span>
                        <span className="w-full"></span>
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(InActiveModal);
