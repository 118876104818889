import { Component, ComponentType, Fragment } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Pagination
import Pagination from "../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard, {
  getAllUsersList,
  // getAllUsersList,
  getSelfDetails,
} from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import "../style.css";
import TagManager from "react-gtm-module";
import { Menu, Transition } from "@headlessui/react";
import DeleteModal from "../../components/DeleteModal";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";
// import InvitationModal from "../../components/InvitationModal";
import ActiveModal from "../../components/ActiveModal";
import InActiveModal from "../../components/InActiveModal";
// import LogsModal from "../../components/LogsModal";
import Button from "../../components/Button";
import { RootState, AppDispatch } from "../../store";
import { CommonState } from "../../store/reducers/common";
import { NotificationType } from "../../store/reducers/notification";
import { User, UserInvitation, WorkSpace } from "../../helpers/types";
import InvitationModal from "../../components/InvitationModal";
import Actions, { ActionsOption } from "../../components/Actions";
import { UserRights, UserRightsName } from "../../constants/defaultUserRights";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "Finexo Login Tool",
    page: "Users List",
  },
  dataLayerName: "PageDataLayer",
};

//Redux mapping

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } }),
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: UPDATE_COMMON, payload }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {};

type State = {
  loading: boolean;
  posX: number | null;
  posY: number | null;
  hoverX: number | null;
  hoverY: number | null;
  showBackDrop: boolean;
  searchText: string;
  users: User[];
  totalRecords: number;
  displayUserDetails: User[];
  selectedGstId: string;
  selectedFirm?: WorkSpace;
  modalOpen: boolean;
  typingTimeout: NodeJS.Timeout | null;
  selectedRow?: User;
  showDeleteModal: boolean;
  showEditModal: boolean;
  active: boolean;
  sentLoading: boolean;
  invitationSentDetails: UserInvitation[];
  invitationSentTotalRecords: number;
  showInvitationModal: boolean;
  showActiveModal: boolean;
  showInActiveModal: boolean;
  skip: 0;
  currPage: 0;
  chunkSize: 25;
};

class Users extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      posX: null,
      posY: null,
      showBackDrop: false,
      searchText: "",
      users: [],
      totalRecords: 0,
      displayUserDetails: [],
      selectedGstId: "",
      selectedFirm: undefined,
      modalOpen: false,
      typingTimeout: null,
      selectedRow: undefined,
      hoverX: null,
      hoverY: null,
      showDeleteModal: false,
      showEditModal: false,
      active: true,
      sentLoading: false,
      invitationSentDetails: [],
      invitationSentTotalRecords: 0,
      showInvitationModal: false,
      showActiveModal: false,
      showInActiveModal: false,
      skip: 0,
      currPage: 0,
      chunkSize: 25,
    };
  }

  noRightNotification = (type: `${UserRightsName} users` | (string & {})) => {
    this.props.addNotification?.(
      "Rights Not Available",
      `You do not have rights to ${type}`,
      "warn",
    );
  };

  userListHeaders = ["USER NAME", "SHORT NAME", "EMAIL", "STATUS", "ACTIONS"];

  userSelfDetailsHeaders = [
    "USER NAME",
    "SHORT NAME",
    "EMAIL",
    "STATUS",
    "ACTIONS",
  ];

  firmInvitationSentHeaders = [
    "FIRM NAME",
    "SEND TO",
    "USER ACCESS RIGHT",
    "STATUS",
    "ACTIONS",
  ];

  // onMount Load data
  componentDidMount() {
    document.title = "Users - Finexo Login Tool";
    this.getUsersList(false);
    this.getInvitaionSentList();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = this.props.params?.firmId;

    if (
      ((prevProps.rights !== this.props.rights &&
        this.props.rights?.userRights) ||
        prevFirmId !== currFirmId) &&
      currFirmId
    ) {
      this.setState({ searchText: "" });
      getSelfDetails(
        currFirmId,
        this.props.updateCommon,
        this.props.addNotification,
      );
      this.getUsersList(false);
      this.getInvitaionSentList();
    }

    if (
      (prevProps.rights !== this.props.rights &&
        this.props.rights?.userRights) ||
      prevState.active !== this.state.active
    ) {
      this.setState({ searchText: "" });
      this.getUsersList(false);
    }

    if (prevState.currPage !== this.state.currPage) {
      this.setState({
        displayUserDetails: this.state.users.slice(
          this.state.currPage * this.state.chunkSize,
          this.state.currPage * this.state.chunkSize + this.state.chunkSize,
        ),
      });
    }
  }

  //Get User Data

  getUsersList = (forSearch: boolean) => {
    const workSpaceId = this.props.params?.firmId;
    const searchText = forSearch ? this.state.searchText : "";
    const active = this.state.active;
    const includeCurrent = false;

    if (!workSpaceId) return;

    this.setState({ loading: true });
    agent.User.getUserList<{
      users: User[];
      totalRecords: number;
    }>(workSpaceId, active, searchText, includeCurrent)
      .then((response) => {
        this.setState({
          users: response.users,
          loading: false,
          totalRecords: response.users.length,
          displayUserDetails: response.users.slice(
            this.state.currPage * this.state.chunkSize,
            this.state.currPage * this.state.chunkSize + this.state.chunkSize,
          ),
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Could not load Firm Details",
          err?.response?.data?.message || err?.message || err,
          "danger",
        );
      });
  };

  getInvitaionSentList = () => {
    const workSpaceId = this.props.params?.firmId;

    if (!workSpaceId) return;

    this.setState({ sentLoading: true });
    agent.Firm.listofInvitationSent<{
      invitations: UserInvitation[];
      totalRecords: number;
    }>(workSpaceId)
      .then((response) => {
        this.setState({
          invitationSentDetails: response.invitations,
          sentLoading: false,
          invitationSentTotalRecords: response.invitations.length,
        });
      })
      .catch((err) => {
        this.setState({ sentLoading: false });
        this.props.addNotification?.(
          "Could not load Sent Invitaion Lists",
          err?.response?.data?.message || err?.message || err,
          "danger",
        );
      });
  };

  handlePageClick = (data: any) => {
    this.setState({
      currPage: data.selected,
    });
  };

  onFirmChange = (item: any) => {
    this.setState({ selectedFirm: item });
  };

  onActionClick = (e: any, menuHeight: number) => {
    // const screenClosness = window.innerHeight - e.clientY;
    // const positionY =
    //   screenClosness < menuHeight ? e.clientY - menuHeight : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: e.clientY,
      showBackDrop: true,
    });
  };

  onDropdownClick = () => {
    this.setState({ showBackDrop: false });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getUsersList(true);
      }, 700),
    });
  };

  updateActive = () => {
    this.setState({ active: !this.state.active, currPage: 0 });
  };

  openAddUserPage = () => {
    const userRights = this.props?.rights?.userRights;
    const createRight = userRights?.create;
    const currentFirmId = this.props.params?.firmId;
    if (createRight) {
      this.props.navigate?.(`/${currentFirmId}/user/add`);
    } else {
      this.props.addNotification?.(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger",
      );
    }
  };

  openDeleteModal = (user: any) => {
    this.setState({ selectedRow: user, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open,
    });
  };

  openRevokeModal = (item: any) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false,
    });
    this.invitationModalSetOpen(true);
  };

  invitationModalSetOpen = (open: boolean) => {
    this.setState({
      showInvitationModal: open,
    });
  };

  openEditModal = (user: any) => {
    const userRights = this.props?.rights?.userRights;
    const editRight = userRights?.edit;
    const currentFirmId = this.props.params?.firmId;
    if (editRight) {
      this.props.updateCommon?.({ editUser: user });
      this.props.navigate?.(`/${currentFirmId}/user/edit`);
    } else {
      this.setState({ showBackDrop: false });
      this.noRightNotification("edit users");
    }
  };

  editModalSetOpen = (open: boolean) => {
    this.setState({
      showEditModal: open,
    });
  };

  openActiveModal = (user: any) => {
    this.setState({ selectedRow: user, showBackDrop: false });
    this.activeModalSetOpen(true);
  };

  activeModalSetOpen = (open: boolean) => {
    this.setState({
      showActiveModal: open,
    });
  };

  openInActiveModal = (user: any) => {
    this.setState({ selectedRow: user, showBackDrop: false });
    this.inActiveModalSetOpen(true);
  };

  inActiveModalSetOpen = (open: boolean) => {
    this.setState({
      showInActiveModal: open,
    });
  };

  showSelfRights = () => {
    const currentFirmId = this.props.params?.firmId;
    this.props.navigate?.(`/${currentFirmId}/user/self-rights`);
  };

  isFirmOwner = (userId: string) => {
    const currentFirm = this.props?.currentFirm;
    const owner = currentFirm?.superAdminId;
    return owner === userId ? true : false;
  };

  onLoad = () => {
    this.setState({ searchText: "" });
    this.getUsersList(false);

    const workSpaceId = this.props.params?.firmId;

    if (!workSpaceId) return;

    getAllUsersList(
      workSpaceId,
      this.props.updateCommon,
      this.props.addNotification,
    );
  };

  UserListActions = (user: User): ActionsOption[] => {
    const activeInactiveOptions: ActionsOption[] = this.state.active
      ? [
          {
            name: "Make Inactive",
            icon: this.props.rights?.userRights.edit
              ? "edit"
              : "outline/lock-closed",
            action: () =>
              this.props.rights?.userRights.edit
                ? this.openInActiveModal(user)
                : this.noRightNotification("make user inactive"),
          },
        ]
      : [
          {
            name: "Make Active",
            icon: this.props.rights?.userRights.edit
              ? "edit"
              : "outline/lock-closed",
            action: () =>
              this.props.rights?.userRights.edit
                ? this.openActiveModal(user)
                : this.noRightNotification("make user active"),
          },
        ];

    return [
      {
        name: "Edit",
        icon: this.props.rights?.userRights.edit
          ? "edit"
          : "outline/lock-closed",
        action: () =>
          this.props.rights?.userRights.edit
            ? this.openEditModal(user)
            : this.noRightNotification("edit users"),
      },
      ...activeInactiveOptions,
      {
        name: "Delete",
        icon: this.props.rights?.userRights.delete
          ? "delete"
          : "outline/lock-closed",
        action: () =>
          this.props.rights?.userRights.delete
            ? this.openDeleteModal(user)
            : this.noRightNotification("delete users"),
      },
    ];
  };

  UserSelfActions: ActionsOption[] = [
    {
      name: "Show Rights",
      icon: "outline/document-report",
      action: this.showSelfRights,
    },
  ];

  InvitationSentActions = (invitation: UserInvitation): ActionsOption[] => {
    return [
      {
        name: "Revoke Invitation",
        icon: "warning",
        action: () =>
          this.props.rights?.userRights.delete
            ? this.openRevokeModal(invitation)
            : this.noRightNotification("revoke invitation"),
      },
    ];
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);

    const {
      selectedRow,
      showInActiveModal,
      showActiveModal,
      showDeleteModal,
      showInvitationModal,
    } = this.state;
    const { name, _id } = selectedRow || {};

    return (
      <Dashboard>
        <div className="mb-8 space-y-6">
          {this.state.showDeleteModal && (
            <DeleteModal
              type={"user"}
              state={{ selectedRow: { name, _id }, showDeleteModal }}
              onLoad={this.onLoad}
              deleteModalSetOpen={this.deleteModalSetOpen}
            />
          )}
          {this.state.showInvitationModal && (
            <InvitationModal
              heading={"Revoke Invitation"}
              type={"Revoke"}
              state={{ selectedRow, showInvitationModal }}
              onLoad={this.getInvitaionSentList}
              invitationModalSetOpen={this.invitationModalSetOpen}
            />
          )}
          {this.state.showInActiveModal && (
            <InActiveModal
              type={"user"}
              state={{ selectedRow: { name, _id }, showInActiveModal }}
              onLoad={this.onLoad}
              inActiveModalSetOpen={this.inActiveModalSetOpen}
            />
          )}

          {this.state.showActiveModal && (
            <ActiveModal
              type={"user"}
              state={{ selectedRow: { name, _id }, showActiveModal }}
              onLoad={this.onLoad}
              activeModalSetOpen={this.activeModalSetOpen}
            />
          )}

          <div className="max-w-7xl mx-auto lg:mx-8">
            <h1 className="text-2xl font-semibold text-gray-900">Users</h1>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6">
              <div className="w-fit">
                <button
                  type="button"
                  className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  onClick={this.openAddUserPage}
                >
                  {this.props?.rights?.userRights.create ? (
                    <Icon name="add" className="h-4 w-4 mr-2" />
                  ) : (
                    <Icon name="outline/lock-closed" className="h-4 w-4 mr-2" />
                  )}
                  Add User
                </button>
              </div>

              {(this.state.totalRecords > 0 ||
                this.state.searchText.length > 0) && (
                <div className="w-full md:w-80 md:col-span-2">
                  <input
                    id="search"
                    name="search"
                    type="search"
                    value={this.state.searchText}
                    placeholder="Search by User Name"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-xs sm:text-sm"
                    onChange={this.handleSearchTextChange}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="relative flex items-start max-w-7xl mx-auto lg:mx-8">
            <div className="flex h-5 items-center">
              <input
                id="toggle-active"
                name="toggle-active"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                checked={this.state.active === false}
                onChange={this.updateActive}
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="comments"
                className="font-medium cursor-pointer text-gray-700"
              >
                Show Inactive Users
              </label>
            </div>
          </div>

          {/* Users List*/}
          <div className="max-w-7xl mx-auto lg:mx-8">
            {/* Users List Cards*/}
            <div className="sm:hidden px-2 sm:px-6 mt-6">
              {!this.state.loading ? (
                this.state.totalRecords > 0 ||
                this.state.active === false ||
                this.state.searchText.length > 0 ? (
                  <ul className="divide-y divide-gray-200 space-y-4">
                    {(this.state.active === false &&
                      this.state.totalRecords === 0) ||
                    (this.state.searchText && this.state.totalRecords === 0) ? (
                      <div className="bg-white shadow-md rounded-lg">
                        <div className="px-4 py-4 sm:px-6">
                          <p className="text-sm text-gray-800 text-center font-semibold">
                            {this.state.searchText &&
                              "No record found matching your search criteria"}
                            {this.state.active === false &&
                              this.state.searchText === "" &&
                              "No record of inactive users"}
                          </p>
                        </div>
                      </div>
                    ) : (
                      this.state.displayUserDetails.map((user, index) => (
                        <li
                          key={user._id}
                          className="bg-white shadow-md rounded-lg"
                        >
                          <div className="px-4 py-4 sm:px-6 grid gap-2 grid-cols-2 relative">
                            <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow uppercase">
                              {user.active ? "Active" : "Inactive"}
                            </span>
                            <p className="text-gray-800 font-semibold mt-auto">
                              <span className="w-8 h-8 p-2 rounded-full cursor-default inline-flex items-center justify-center bg-gray-700 text-white mr-3 text-sm xs:text-base">
                                {user.shortname}
                              </span>
                              <span className="text-sm xs:text-lg font-bold capitalize text-indigo-600">
                                {user.name}
                              </span>
                            </p>
                            <div className="justify-self-end mt-1">
                              <Actions
                                responsive
                                options={this.UserListActions(user)}
                              />
                            </div>
                            <div className="text-sm text-gray-800 mt-auto col-span-2 max-w-full">
                              <p className="font-bold truncate">{user.email}</p>
                            </div>
                          </div>
                        </li>
                      ))
                    )}
                  </ul>
                ) : (
                  <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg bg-gray-100">
                    <Icon
                      name="outline/document-add"
                      className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                      strokeWidth="1"
                    />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No Users Entry
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by adding a new User.
                    </p>
                    <div className="mt-6">
                      <Button
                        name="Add User"
                        onClick={this.openAddUserPage}
                        icon={
                          this.props?.rights?.userRights.create
                            ? "add"
                            : "outline/lock-closed"
                        }
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div key={i} className="bg-white shadow-md rounded-lg">
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-2 items-center relative ${
                          i === 0 ? "text-left" : "text-center"
                        }`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(4)].map((e, i) => (
                          <Skeleton key={i} height="1rem" />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Users List Table*/}
            <div className="hidden sm:block">
              {this.state.totalRecords > 0 ||
              this.state.active === false ||
              this.state.searchText.length > 0 ? (
                <div className="mt-6">
                  <div id="table-scroll" className="overflow-auto">
                    <div className="inline-block min-w-full py-2 px-1 align-middle">
                      <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                        <table className="min-w-full border-collapse border shadow-sm">
                          <thead className="bg-gray-50">
                            <tr>
                              {this.userListHeaders.map((header, index) => (
                                <th
                                  key={header}
                                  scope="col"
                                  className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider sm:pl-6 ${
                                    index === this.userListHeaders.length - 1
                                      ? "text-center"
                                      : "text-left"
                                  }`}
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {!this.state.loading ? (
                              (this.state.active === false &&
                                this.state.totalRecords === 0) ||
                              (this.state.searchText &&
                                this.state.totalRecords === 0) ? (
                                <tr>
                                  <td
                                    colSpan={this.userListHeaders.length}
                                    className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                                  >
                                    {this.state.searchText &&
                                      "No record found matching your search criteria"}
                                    {this.state.active === false &&
                                      this.state.searchText === "" &&
                                      "No record of inactive users"}
                                  </td>
                                </tr>
                              ) : (
                                this.state.displayUserDetails?.map(
                                  (user, index) => (
                                    <tr
                                      key={user._id}
                                      className={
                                        index % 2 === 0
                                          ? "bg-white"
                                          : "bg-gray-100"
                                      }
                                    >
                                      <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-6">
                                        {user.name}
                                      </td>
                                      <td className="w-3/10 px-6 py-3 text-sm text-gray-500 relative">
                                        {user.shortname}
                                      </td>
                                      <td className="w-4/10 px-6 py-3 whitespace-nowrap font-bold text-sm text-gray-900">
                                        {user.email}
                                      </td>
                                      <td className="w-4/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                        {user.active ? "Active" : "Inactive"}
                                      </td>
                                      <td className="w-4/10 px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-900">
                                        <Actions
                                          options={this.UserListActions(user)}
                                        />
                                      </td>
                                    </tr>
                                  ),
                                )
                              )
                            ) : (
                              [...Array(5)].map((e, i) => (
                                <tr key={i} className="bg-white">
                                  {[...Array(this.userListHeaders.length)].map(
                                    (e, i) => (
                                      <td
                                        key={i}
                                        className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                      >
                                        <Skeleton />
                                      </td>
                                    ),
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg bg-gray-100">
                  <Icon
                    name="outline/document-add"
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    strokeWidth="1"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No Users Entry
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new User.
                  </p>
                  <div className="mt-6">
                    <Button
                      name="Add User"
                      onClick={this.openAddUserPage}
                      icon={
                        this.props?.rights?.userRights.create
                          ? "add"
                          : "outline/lock-closed"
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <Pagination
            displayRecords={this.state.displayUserDetails}
            totalRecords={this.state.totalRecords}
            currPage={this.state.currPage}
            chunkSize={this.state.chunkSize}
            hidechunkSizeSelector={true}
            handlePageClick={this.handlePageClick}
            className="my-4"
          />

          {/* User Self Details */}
          <div className="max-w-7xl mx-auto lg:mx-8">
            <h1 className="text-2xl font-semibold text-gray-900">Yourself</h1>
            {/* User Self Details Cards */}
            <div className="sm:hidden px-2 sm:px-6 mt-6">
              <ul className="divide-y divide-gray-200 space-y-4">
                <li className="bg-white shadow-md rounded-lg">
                  <div className="px-4 py-4 sm:px-6 grid gap-2 grid-cols-2 relative">
                    <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow uppercase">
                      {this.props?.currentUser?.active ? "Active" : "Inactive"}
                    </span>
                    <p className="text-gray-800 font-semibold mt-auto">
                      <span className="w-8 h-8 p-2 rounded-full cursor-default inline-flex items-center justify-center bg-gray-700 text-white mr-3 text-sm xs:text-base">
                        {this.props?.currentUser?.shortname}
                      </span>
                      <span className="text-sm xs:text-lg font-bold capitalize text-indigo-600">
                        {this.props?.currentUser?.name}
                      </span>
                    </p>
                    <div className="justify-self-end mt-1">
                      <Actions responsive options={this.UserSelfActions} />
                    </div>
                    <div className="text-sm text-gray-800 mt-auto col-span-2 max-w-full">
                      <p className="font-bold truncate">
                        {this.props?.currentUser?.email}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            {/* User Self Details Table */}
            <div className="mt-6 hidden sm:block">
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50">
                        <tr>
                          {this.userSelfDetailsHeaders.map((header, index) => (
                            <th
                              key={header}
                              scope="col"
                              className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider sm:pl-6 ${
                                index === this.userSelfDetailsHeaders.length - 1
                                  ? "text-center"
                                  : "text-left"
                              }`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        <tr>
                          <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-6">
                            {this.props?.currentUser?.name}
                          </td>
                          <td className="w-3/10 px-6 py-3 text-sm text-gray-500 relative">
                            {this.props?.currentUser?.shortname}
                          </td>
                          <td className="w-4/10 px-6 py-3 whitespace-nowrap font-bold text-sm text-gray-900">
                            {this.props?.currentUser?.email}
                          </td>
                          <td className="w-4/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                            {this.props?.currentUser?.active
                              ? "Active"
                              : "Inactive"}
                          </td>
                          <td className="w-4/10 px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-900">
                            <Actions options={this.UserSelfActions} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* User Sent Invitation */}
          <div className="max-w-7xl mx-auto lg:mx-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              User Invitation Sent
            </h1>
            {/* User Invitation Sent Cards*/}
            <div className="sm:hidden px-2 sm:px-6 mt-6">
              {!this.state.sentLoading ? (
                this.state.invitationSentDetails.length > 0 ? (
                  <ul className="divide-y divide-gray-200 space-y-4">
                    {this.state.invitationSentDetails.map(
                      (invitation, index) => (
                        <li
                          key={invitation._id}
                          className="bg-white shadow-md rounded-lg"
                        >
                          <div className="px-4 py-4 sm:px-6 grid gap-2 grid-cols-2 relative">
                            <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow uppercase">
                              {invitation.status}
                            </span>
                            <p className="text-sm text-gray-800 font-semibold mt-auto">
                              <span>Name - </span>
                              <span className="font-bold">
                                {invitation.workSpaceId.name}
                              </span>
                            </p>
                            <div className="justify-self-end mt-1">
                              <Actions
                                responsive
                                options={this.InvitationSentActions(invitation)}
                              />
                            </div>
                            <div className="text-sm text-gray-800 mt-auto col-span-2 max-w-full">
                              <p>
                                <span>Role - </span>
                                <span className="font-bold capitalize text-indigo-600">
                                  {invitation.userAccessRole[0].toUpperCase() +
                                    invitation.userAccessRole.slice(1)}
                                </span>
                              </p>
                              <p className="font-bold truncate">
                                {invitation.email}
                              </p>
                            </div>
                          </div>
                        </li>
                      ),
                    )}
                  </ul>
                ) : (
                  <div className="bg-white shadow-md rounded-lg">
                    <div className="px-4 py-4 sm:px-6">
                      <p className="text-sm text-gray-800 text-center font-semibold">
                        No Invitations Sent
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div key={i} className="bg-white shadow-md rounded-lg">
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-2 items-center relative ${
                          i === 0 ? "text-left" : "text-center"
                        }`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(4)].map((e, i) => (
                          <Skeleton key={i} height="1rem" />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* User Invitation Sent Table*/}
            <div className="mt-6 hidden sm:block">
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50">
                        <tr>
                          {this.firmInvitationSentHeaders.map(
                            (header, index) => (
                              <th
                                key={header}
                                scope="col"
                                className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider sm:pl-6 ${
                                  index ===
                                  this.firmInvitationSentHeaders.length - 1
                                    ? "text-center"
                                    : "text-left"
                                }`}
                              >
                                {header}
                              </th>
                            ),
                          )}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {!this.state.sentLoading ? (
                          this.state.invitationSentDetails.length === 0 ? (
                            <tr>
                              <td
                                colSpan={this.firmInvitationSentHeaders.length}
                                className="pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                              >
                                <div className="w-fit m-0">
                                  No Invitations Sent
                                  <span className="invisible">hide</span>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            this.state.invitationSentDetails?.map(
                              (invitation, index) => (
                                <tr
                                  key={invitation._id}
                                  className={
                                    index % 2 === 0 ? undefined : "bg-gray-100"
                                  }
                                >
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-6">
                                    {invitation.workSpaceId.name}
                                  </td>
                                  <td className="w-4/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                    {invitation.email}
                                  </td>
                                  <td className="w-2/10 px-6 py-3 whitespace-nowrap text-sm font-bold text-gray-900">
                                    {invitation.userAccessRole[0].toUpperCase() +
                                      invitation.userAccessRole.slice(1)}
                                  </td>
                                  <td className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                    {invitation.status}
                                  </td>
                                  <td className="w-2/10 px-6 py-3 text-center whitespace-nowrap text-sm text-gray-900">
                                    <Actions
                                      options={this.InvitationSentActions(
                                        invitation,
                                      )}
                                    />
                                  </td>
                                </tr>
                              ),
                            )
                          )
                        ) : (
                          [...Array(5)].map((e, i) => (
                            <tr key={i} className="bg-white">
                              {[
                                ...Array(this.firmInvitationSentHeaders.length),
                              ].map((e, i) => (
                                <td
                                  key={i}
                                  className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose<ComponentType<Props>>(connector, withRouter)(Users);
