import React, { ReactNode } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { withRouter } from "../helpers/withRouter";
import { ADD_NOTIFICATION } from "../store/types";
import Icon, { IconNames } from "./Icon";
import { NotificationType } from "../store/reducers/notification";
import { AppDispatch, RootState } from "../store";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";

//Redux mapping

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type HeroIconsType = React.ForwardRefExoticComponent<
  Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & React.RefAttributes<SVGSVGElement>
>;

interface Props {
  name: string;
  icon?: IconNames | HeroIconsType; // HeroIcon component name or Icon component name
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  type?: "button" | "link";
  to?: string;
}

class Button extends React.Component<Props & PropsFromRedux> {
  render() {
    const buttonClass =
      "relative inline-flex items-center gap-x-2 pl-4 pr-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 disabled:cursor-not-allowed";

    return this.props.type === "link" ? (
      <Link
        to={this.props.to!}
        className={this.props.className || buttonClass}
        onClick={this.props.onClick}
      >
        <CheckBadgeIcon />
        {typeof this.props.icon === "string" ? (
          <Icon
            name={this.props.icon}
            className="mr-2 h-4 w-4"
            aria-hidden="true"
          />
        ) : this.props.icon ? (
          <this.props.icon className="mr-2 h-4 w-4" aria-hidden="true" />
        ) : null}
        {this.props.name}
      </Link>
    ) : (
      <button
        type="button"
        className={this.props.className || buttonClass}
        disabled={this.props.disabled || false}
        onClick={this.props.onClick}
      >
        <span className="mr-2 w-4">
          {typeof this.props.icon === "string" ? (
            <Icon
              name={this.props.icon}
              className="h-4 w-4"
              aria-hidden="true"
            />
          ) : this.props.icon ? (
            <this.props.icon className="h-4 w-4" aria-hidden="true" />
          ) : null}
        </span>
        {this.props.name}
        <span className="w-3"></span>
      </button>
    );
  }
}

export default compose(
  connector,
  withRouter
)(Button) as React.ComponentType<Props>;
