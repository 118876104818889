import { ChangeEvent, Component, ComponentType, Fragment } from "react";
import Dashboard from "../../components/Dashboard";
import Pagination from "../../components/Pagination";
import Skeleton from "react-loading-skeleton";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { ADD_NOTIFICATION } from "../../store/types";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";
import ActiveModal from "../../components/ActiveModal";
import InActiveModal from "../../components/InActiveModal";
import { NotificationType } from "../../store/reducers/notification";
import Actions, { ActionsOption } from "../../components/Actions";
import { itemsPerPage } from "../../constants/itemsPerPage";
import agent from "../../agent";
import { downloadFile } from "../../helpers/downloadFile";
import { AxiosResponseHeaders } from "axios";
import { ClientDetailsResponse } from "../../helpers/types";
import { CommonState } from "../../store/reducers/common";
import DeleteModal from "../../components/DeleteModal";
import TagManager from "react-gtm-module";
import { Menu, Transition } from "@headlessui/react";
import { ClientRightsName } from "../../constants/defaultUserRights";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "Finexo Login Tool",
    page: "Client List Page",
  },
  dataLayerName: "PageDataLayer",
};

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } }),
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: "UPDATE_COMMON", payload }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {};

type State = {
  loading: boolean;
  clientsList: ClientDetailsResponse[];
  totalRecords: number;
  currPage: number;
  chunkSize: number;
  searchText: string;
  typingTimeout?: NodeJS.Timeout;
  active: boolean;
  showFilter: boolean;
  showInActiveModal: boolean;
  showActiveModal: boolean;
  showDeleteModal: boolean;
  showLogsModal: boolean;
  selectedRow: ClientDetailsResponse | null;
  downloading: boolean;
  showOptionBackDrop: boolean;
  rotate: boolean;
};

class Client extends Component<Props, State> {
  state: State = {
    loading: false,
    clientsList: [],
    totalRecords: 0,
    currPage: 0,
    chunkSize: itemsPerPage[0].name,
    searchText: "",
    active: true,
    showFilter: false,
    showInActiveModal: false,
    showActiveModal: false,
    showDeleteModal: false,
    showLogsModal: false,
    selectedRow: null,
    downloading: false,
    showOptionBackDrop: false,
    rotate: false,
  };

  noRightNotification = (
    type: `${ClientRightsName} clients` /*  | (string & {}) */,
  ) => {
    this.props.addNotification?.(
      "Rights Not Available",
      `You do not have rights to ${type}`,
      "warn",
    );
  };

  componentDidMount() {
    document.title = "Clients - Finexo Login Tool";
    this.getClients(false);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.params?.firmId !== this.props.params?.firmId ||
      prevState.currPage !== this.state.currPage ||
      prevState.chunkSize !== this.state.chunkSize ||
      prevState.active !== this.state.active
    ) {
      this.getClients(false);
    }
  }

  getClients = (download: boolean) => {
    if (download && !this.props.rights?.clientRights.export)
      return this.noRightNotification("export clients");

    const workSpaceId = this.props?.params?.firmId;
    if (!workSpaceId) return;

    download
      ? this.setState({ downloading: true })
      : this.setState({ loading: true });

    const { currPage, chunkSize, searchText, active } = this.state;

    agent.Clients.getClients<
      | { clients: ClientDetailsResponse[]; count: number }
      | { data: Blob; headers: AxiosResponseHeaders }
    >(workSpaceId, {
      type: "",
      searchText,
      active,
      limit: chunkSize,
      skip: currPage * chunkSize,
      download,
    })
      .then((res) => {
        if (download) {
          downloadFile(res, "Finexo Login Tool - Client List.xlsx");
        } else if ("clients" in res) {
          const { clients, count } = res;

          this.setState({
            clientsList: clients,
            totalRecords: count,
          });
        }
      })
      .catch((error) => {
        this.props.addNotification?.(
          "Error Occured",
          error?.response?.data?.error || error?.error,
          "danger",
        );
      })
      .finally(() => {
        this.setState({ loading: false, downloading: false });
      });
  };

  onOptionsDropdownClick = () => {
    this.setState({
      rotate: !this.state.rotate,
      showOptionBackDrop: !this.state.showOptionBackDrop,
    });
  };

  handleItemPerPage = (value: { name: number }) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  handlePageClick = (data: { selected: number }) => {
    this.setState({ currPage: data.selected });
  };

  handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      currPage: 0,
      searchText: e.target.value,
      typingTimeout: setTimeout(() => {
        this.getClients(false);
      }, 700),
    });
  };

  importClients = () => {
    this.props.navigate?.(`/${this.props.params?.firmId}/clients/import`);
  };

  updateActive = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      active: !this.state.active,
      currPage: 0,
    });
  };

  openAddClientPage = () => {
    this.props.navigate?.(`/${this.props.params?.firmId}/clients/add`);
  };

  openEditClientPage = (selectedClient: ClientDetailsResponse) => {
    this.props.updateCommon?.({ selectedClient });
    this.props.navigate?.(
      `/${this.props.params?.firmId}/clients/edit?clientId=${selectedClient._id}`,
    );
  };

  openInActiveModal = () => {
    this.setState({ showInActiveModal: !this.state.showInActiveModal });
  };

  openActiveModal = () => {
    this.setState({ showActiveModal: !this.state.showActiveModal });
  };

  openDeleteModal = () => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  clientActions = (client: ClientDetailsResponse): ActionsOption[] => {
    const activeInactiveOptions: ActionsOption[] = this.state.active
      ? [
          {
            name: "Make Inactive",
            icon: "edit",
            action: () => {
              this.setState({ selectedRow: client });
              this.openInActiveModal();
            },
          },
        ]
      : [
          {
            name: "Make Active",
            icon: "edit",
            action: () => {
              this.setState({ selectedRow: client });
              this.openActiveModal();
            },
          },
        ];

    return [
      {
        name: "Edit",
        icon: "edit",
        action: () => this.openEditClientPage(client),
      },
      ...activeInactiveOptions,
      {
        name: "Delete",
        icon: "delete",
        action: () => {
          this.setState({ selectedRow: client });
          this.openDeleteModal();
        },
      },
    ];
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);

    const headers = ["FILE NO.", "CLIENT NAME", "TRADE NAME", "ACTIONS"];

    const TableHeader = () => {
      return (
        <thead className="bg-gray-50">
          <tr>
            {headers.map((header) => (
              <th
                key={header}
                scope="col"
                className={`w-fit px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider ${
                  header === "ACTIONS" ? "text-center" : "text-left"
                }`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
      );
    };

    const { selectedRow, showInActiveModal, showActiveModal, showDeleteModal } =
      this.state;
    const { name, _id } = selectedRow || {};

    return (
      <Dashboard>
        {this.state.showInActiveModal && (
          <InActiveModal
            type={"client"}
            state={{ selectedRow: { name, _id }, showInActiveModal }}
            onLoad={() => this.getClients(false)}
            inActiveModalSetOpen={this.openInActiveModal}
          />
        )}

        {this.state.showActiveModal && (
          <ActiveModal
            type={"client"}
            state={{ selectedRow: { name, _id }, showActiveModal }}
            onLoad={() => this.getClients(false)}
            activeModalSetOpen={this.openActiveModal}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteModal
            type={"client"}
            state={{ selectedRow: { name, _id }, showDeleteModal }}
            onLoad={() => this.getClients(false)}
            deleteModalSetOpen={this.openDeleteModal}
          />
        )}
        <div className="max-w-7xl mx-auto lg:mx-8 ">
          <h1 className="text-2xl font-semibold text-gray-900">Clients</h1>
        </div>

        <div className="flex justify-between gap-3 mt-6 lg:mx-8">
          <div className="hidden md:inline-block">
            <Button
              name="Add Clients"
              icon="add"
              onClick={this.openAddClientPage}
            />
          </div>

          <div className="flex items-center gap-4">
            {(this.state.totalRecords > 0 ||
              this.state.searchText.length > 0) &&
              !this.state.showFilter && (
                <div className="w-64 sm:w-80">
                  <input
                    id="search"
                    name="search"
                    type="search"
                    value={this.state.searchText}
                    placeholder="Search by File No, Client Name or Trade Name"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-xs sm:text-sm"
                    onChange={this.handleSearchTextChange}
                  />
                </div>
              )}
          </div>

          <Menu as="div" className="inline-block md:hidden relative">
            <Menu.Button
              onClick={this.onOptionsDropdownClick}
              className="relative inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-600 w-fit sm:pl-4 sm:pr-2"
            >
              <Icon
                name="outline/settings"
                className={`h-5 w-5`}
                aria-hidden="true"
              />
              <span>Options</span>
              <Icon
                name="dropdown-arrow"
                className={`h-5 w-5 ${this.state.rotate ? "rotate-90" : ""}`}
                aria-hidden="true"
              />
            </Menu.Button>
            {this.state.showOptionBackDrop && (
              <div
                className="fixed top-0 left-0 z-10 w-full h-screen"
                onClick={this.onOptionsDropdownClick}
              ></div>
            )}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="absolute z-[100] right-0">
                <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item as="div" onClick={this.onOptionsDropdownClick}>
                      <Button
                        name="Add Clients"
                        className="flex lg:hidden items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        icon="add"
                        onClick={this.openAddClientPage}
                      />
                    </Menu.Item>
                    <Menu.Item as="div" onClick={this.onOptionsDropdownClick}>
                      <Button
                        name="Import"
                        className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        icon="outline/user-plus"
                        disabled={this.state.loading}
                        onClick={this.importClients}
                      />
                    </Menu.Item>
                    <Menu.Item as="div" onClick={this.onOptionsDropdownClick}>
                      <Button
                        className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        disabled={
                          this.state.loading ||
                          this.state.clientsList.length === 0
                        }
                        name="Export"
                        onClick={() => this.getClients(true)}
                        icon="outline/download"
                      />
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </div>
            </Transition>
          </Menu>

          <div className="hidden md:flex justify-between gap-x-2">
            <Button
              name="Import"
              onClick={this.importClients}
              icon="outline/user-plus"
              disabled={this.state.loading}
            />
            <Button
              name="Export"
              onClick={() => this.getClients(true)}
              disabled={this.state.clientsList.length === 0}
              icon={
                this.props?.rights?.clientRights?.export
                  ? this.state.downloading
                    ? "loading"
                    : "outline/download"
                  : "outline/lock-closed"
              }
            />
          </div>
        </div>

        <div className="relative flex items-start max-w-7xl mx-auto mt-6 lg:mx-8">
          <div className="flex h-5 items-center">
            <input
              id="toggle-active"
              name="toggle-active"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
              checked={this.state.active === false}
              onChange={this.updateActive}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="toggle-active"
              className="font-medium cursor-pointer text-gray-700"
            >
              Show Inactive Clients
            </label>
          </div>
        </div>
        {!this.state.loading && this.state.clientsList ? (
          this.state.totalRecords > 0 ||
          this.state.searchText.length > 0 ||
          this.state.active === false ||
          this.state.showFilter ? (
            <div className="max-w-7xl mx-auto">
              <div className="mt-6 lg:mx-8 flex flex-col">
                <div id="table-scroll" className="overflow-auto">
                  <div className="inline-block min-w-full py-2 px-1 align-middle">
                    <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full border-collapse border shadow-sm">
                        <TableHeader />
                        {(this.state.active === false &&
                          this.state.totalRecords === 0) ||
                        (this.state.searchText &&
                          this.state.totalRecords === 0) ||
                        (this.state.showFilter &&
                          this.state.totalRecords === 0) ? (
                          <tbody>
                            <tr>
                              <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                {(this.state.searchText ||
                                  this.state.showFilter) &&
                                  "No record found matching your search criteria"}

                                {this.state.active === false &&
                                  this.state.searchText === "" &&
                                  "No record of inactive clients"}
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody className="bg-white">
                            {this.state.clientsList?.map((client, index) => (
                              <tr
                                key={client._id}
                                className={
                                  index % 2 === 0 ? undefined : "bg-gray-100"
                                }
                              >
                                <td className="w-2/12 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-6">
                                  {client.fileNo ? client.fileNo : "-"}
                                </td>
                                <td className="w-4/12 px-6 py-3 text-sm text-gray-500 relative capitalize">
                                  {client.name}
                                </td>
                                <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-6">
                                  {client.tradeName ? client.tradeName : "-"}
                                </td>
                                <td className="w-4/10 px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-500">
                                  <Actions
                                    options={this.clientActions(client)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg">
              <Icon
                name="outline/document-add"
                className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                strokeWidth="1"
              />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No Clients Entry
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by adding a new Client.
              </p>
              <div className="mt-6">
                <Button
                  name="Add Clients"
                  icon="add"
                  onClick={this.openAddClientPage}
                />
              </div>
            </div>
          )
        ) : (
          <div className={"max-w-7xl mx-auto lg:mx-8"}>
            <div className="py-6">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                        <TableHeader />
                        <tbody>
                          {[...Array(headers.length)].map((e, i) => (
                            <tr key={i} className="bg-white">
                              {[...Array(4)].map((e, i) => (
                                <td
                                  key={i}
                                  className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Pagination
          displayRecords={this.state.clientsList}
          totalRecords={this.state.totalRecords}
          currPage={this.state.currPage}
          chunkSize={this.state.chunkSize}
          handlePageClick={this.handlePageClick}
          handleItemPerPage={this.handleItemPerPage}
          className="my-4"
        />
      </Dashboard>
    );
  }
}
export default compose<ComponentType<Props>>(connector, withRouter)(Client);
