import { UserRights } from "../../constants/defaultUserRights";
import {
  WorkSpace,
  User,
  ClientDetailsToAdd,
  UserInProps
} from "../../helpers/types";
import { UPDATE_COMMON } from "../types";

const initialState = {
  urlInfo: "",
  firms: [] as WorkSpace[],
  isFirmPresent: false,
  rights: undefined as UserRights | undefined,
  currentFirm: undefined as WorkSpace | undefined,
  currentUser: undefined as User | undefined,
  currentModal: undefined as
    | { modalName: string; fetchAgain?: boolean }
    | undefined,
  selectedClient: undefined as ClientDetailsToAdd | undefined,
  users: [] as UserInProps[],
  editUser: undefined as User | undefined
};

export type CommonState = typeof initialState;
export type CommonAction = {
  type: typeof UPDATE_COMMON;
  payload: Partial<CommonState>;
};

const commonReducer = (state = initialState, action: CommonAction) => {
  switch (action.type) {
    case UPDATE_COMMON:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default commonReducer;
