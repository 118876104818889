import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../types";

// Defining Structure of notification object
interface INotification {
  notifications: {
    color: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    id?: string;
    message?: string;
    title: string;
    type?: string;
  }[];
}

// Initializing empty Notification Array
const initialState: INotification = {
  notifications: []
};

// Types of Notifications
const NotificationTypes = [
  {
    type: "success",
    icon: CheckCircleIcon,
    color: "green"
  },
  {
    type: "danger",
    icon: ExclamationCircleIcon,
    color: "red"
  },
  {
    type: "warn",
    icon: InformationCircleIcon,
    color: "orange"
  }
] as const;

export type NotificationType = (typeof NotificationTypes)[number]["type"];

export interface NotificationPayload {
  id?: string;
  title: string;
  message: string;
  type: NotificationType;
}

export type NotificationAction =
  | { type: typeof ADD_NOTIFICATION; payload: NotificationPayload }
  | {
      type: typeof REMOVE_NOTIFICATION;
      payload: { id: NotificationPayload["id"] };
    };
// Notifications Reducer
const notificationReducer = (
  state = initialState,
  action: NotificationAction
) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      const id = Date.now().toString(36);
      const notify =
        action.payload.type === "success"
          ? NotificationTypes[0]
          : action.payload.type === "danger"
          ? NotificationTypes[1]
          : NotificationTypes[2];

      return {
        ...state,
        notifications: state.notifications.concat([
          {
            color: notify.color,
            icon: notify.icon as (
              props: React.SVGProps<SVGSVGElement>
            ) => JSX.Element,
            id,
            message: action.payload.message,
            title: action.payload.title,
            type: action.payload.type
          }
        ])
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(notification => {
          return action.payload.id !== notification.id;
        })
      };
    default:
      return state;
  }
};

//ACTIONS

export const addNotification = (
  title: string,
  message: string,
  type: NotificationType
) => {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      title,
      message,
      type
    }
  };
};

export default notificationReducer;
