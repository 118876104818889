import React from "react";
//Redux
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";

import agent from "../../agent";
// Routing
//import routes from "../../constants/routes";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { RootState, AppDispatch } from "../../store";
import { NotificationType } from "../../store/reducers/notification";
import { ADD_NOTIFICATION } from "../../store/types";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & Partial<WithRouterProps<{ token: string }>>;

type State = { logging: boolean };

//Main Class
class SignupToken extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      logging: false
    };
  }

  verify = () => {
    this.setState({ logging: true });
    const { token } = this.props.params as { token: string };
    agent.Auth.verifySignupToken(token)
      .then((response: any) => {
        this.setState({ logging: false });
        this.props.navigate?.("/");
        this.props.addNotification?.(
          "Successful",
          "Successfully signed up. Please Login to continue.",
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        this.props.addNotification?.(
          "Failed!",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  componentDidMount() {
    this.verify();
  }

  render() {
    return <div></div>;
  }
}

export default compose(
  connector,
  withRouter
)(SignupToken) as React.ComponentType;
