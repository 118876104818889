import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

export type DefaultParams = { firmId: string };

export interface WithRouterProps<TParams = DefaultParams> {
  location: ReturnType<typeof useLocation>;
  params: TParams & DefaultParams;
  navigate: ReturnType<typeof useNavigate>;
  searchParams: URLSearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>;
}

export const withRouter = <Props extends WithRouterProps>(
  Component: React.ComponentType<Props>
) => {
  return (props: Omit<Props, keyof WithRouterProps>) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    return (
      <Component
        {...(props as Props)}
        location={location}
        params={params}
        navigate={navigate}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    );
  };
};
