import { Menu, Transition } from "@headlessui/react";
import { Component, Fragment, MouseEvent } from "react";
import Icon, { IconNames } from "./Icon";

export type ActionsOption = {
  name: string;
  icon: IconNames;
  action: () => void;
};

interface ActionsProps {
  options: ActionsOption[];
  responsive?: boolean;
}

interface State {
  showBackDrop: boolean;
  xPos: number;
  yPos: number;
}

class Actions extends Component<ActionsProps, State> {
  state: State = {
    showBackDrop: false,
    xPos: 0,
    yPos: 0
  };

  handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 155 ? e.clientY - 155 : e.clientY;
    this.setState({
      showBackDrop: true,
      xPos: e.clientX,
      yPos: positionY
    });
  };

  handleClose = () => {
    this.setState({ showBackDrop: false });
  };

  render() {
    return (
      <Menu as="div" className="inline-block">
        <Menu.Button onClick={this.handleOpen}>
          <span className="sr-only">Open options</span>
          <Icon
            name="horizontal-dots"
            className={`${this.props.responsive && "max-md:hidden"} h-5 w-5`}
            aria-hidden="true"
          />
          {this.props.responsive && (
            <Icon
              name="vertical-dots"
              className="md:hidden h-4 w-4"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={this.handleClose}
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              zIndex: 100,
              top: `${this.state.yPos}px`,
              left: `${this.state.xPos - 230}px`,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {this.props.options.map(option => (
                  <Menu.Item key={option.name}>
                    <button
                      className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                      onClick={() => {
                        option.action();
                        this.handleClose();
                      }}
                    >
                      <Icon name={option.icon} className="h-5 w-5 mr-2" />
                      <span>{option.name}</span>
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

export default Actions;
