import React, { ComponentType } from "react";
import { Link } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import { compose } from "redux";
import { withRouter } from "../helpers/withRouter";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../store/types";
import { NotificationType } from "../store/reducers/notification";
import { AppDispatch, RootState } from "../store";

//Redux mapping

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux> & {
  loggedIn: boolean;
};

class NotFound extends React.Component<Props> {
  componentDidMount() {
    document.title = "404 Not Found";
  }

  render() {
    return this.props.loggedIn ? (
      <Dashboard>
        <NotFoundpage {...this.props} />
      </Dashboard>
    ) : (
      <NotFoundpage {...this.props} />
    );
  }
}

class NotFoundpage extends React.Component<Props> {
  render() {
    return (
      <div
        className={`${
          this.props.loggedIn ? "min-h-[75vh]" : "min-h-screen"
        } grow py-16 px-6 sm:py-24 grid place-items-center lg:px-8`}
      >
        <div className="mx-auto max-w-max">
          <div className="sm:flex text-center sm:text-left">
            <p className="text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Page not found
                </h1>
                <p className="mt-3 text-base text-gray-500">
                  Please check the URL in the address bar and try again.
                </p>
              </div>
              <div className="mt-10 flex justify-center sm:justify-start space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to={
                    this.props.loggedIn
                      ? `${this.props.currentFirm?._id}/clients/list`
                      : "/"
                  }
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Go back home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<ComponentType<Props>>(connector, withRouter)(NotFound);
