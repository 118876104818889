import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Option = {
  title: string;
  description?: string;
};

interface Props<T extends Option> {
  label?: string;
  options: T[];
  selectedOption?: T;
  setSelectedOption: (value: T) => void;
}

export default function RadioButtonGroup<TOption extends Option>({
  label,
  options,
  selectedOption,
  setSelectedOption
}: Props<TOption>) {
  return (
    <RadioGroup value={selectedOption} onChange={setSelectedOption}>
      {label && (
        <RadioGroup.Label className="text-base font-medium text-gray-900">
          {label}
        </RadioGroup.Label>
      )}

      <div
        className={`${
          label && "mt-4"
        } grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4`}
      >
        {options.map(option => (
          <RadioGroup.Option
            key={option.title}
            value={option}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "border-indigo-500 ring-2 ring-indigo-500" : "",
                "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex-1 flex">
                  <span className="flex flex-col gap-3">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-gray-900"
                    >
                      {option.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex items-center text-sm text-gray-500"
                    >
                      {option.description}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-indigo-500" : "border-transparent",
                    "absolute -inset-px rounded-lg pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
