import { Component, ComponentType } from "react";
import { OrderDetails } from ".";
import agent from "../../agent";
import Skeleton from "react-loading-skeleton";
import { ConnectedProps, connect } from "react-redux";
import { ADD_NOTIFICATION } from "../../store/types";
import { AppDispatch, RootState } from "../../store";
import { WithRouterProps, withRouter } from "../../helpers/withRouter";
import Dashboard from "../../components/Dashboard";
import { compose } from "redux";
import { NotificationType } from "../../store/reducers/notification";

//Redux mapping

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: { title, message, type }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {
  currentFirm: any;
};

interface State {
  orderId: string | null;
  loading: boolean;
  paymentStatus: OrderDetails["status"] | "";
  paymentFailureReason: string | undefined;
  redirectTimeout: NodeJS.Timeout | null;
}

class PaymentReponse extends Component<Props, State> {
  state: State = {
    orderId: null,
    loading: false,
    paymentStatus: "",
    paymentFailureReason: "",
    redirectTimeout: null
  };

  getPaymentStatus = () => {
    let orderId = new URLSearchParams(window.location.search).get("orderid");
    this.setState({ orderId });

    if (orderId) {
      this.setState({ loading: true });
      agent.Payment.getPaymentStatus<{ order: OrderDetails[] }>(orderId)
        .then(res => {
          if (res.order.length === 0) {
            this.setState({ loading: false });
            this.props.addNotification?.(
              "Could not load payment status",
              "Please try again later",
              "danger"
            );
            return;
          }

          const paymentStatus = res.order[0].status;
          const paymentFailureReason = res.order[0].respmsg;

          this.setState({
            loading: false,
            paymentStatus,
            paymentFailureReason
          });
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.props.addNotification?.(
            "Could not load payment status",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  componentDidMount() {
    this.getPaymentStatus();
  }

  componentWillUnmount(): void {
    if (this.state.redirectTimeout) {
      clearTimeout(this.state.redirectTimeout);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const currentFirmId = this.props.currentFirm?._id;
    if (currentFirmId !== (prevProps as any).currentFirm?._id) {
      if (!this.state.orderId) {
        this.props.navigate?.(`${currentFirmId}/subscriptions`);
      } else {
        this.setState({
          redirectTimeout: setTimeout(() => {
            this.state.paymentStatus === "TXN_SUCCESS"
              ? this.props.navigate?.(`/${currentFirmId}/clients/list`)
              : this.props.navigate?.(`/${currentFirmId}/subscriptions`);
          }, 5000)
        });
      }
    }
  }

  render() {
    return (
      <Dashboard>
        <div className="min-h-[75vh] grid gap-6 place-content-center">
          {this.state.loading ? (
            <div className="text-center">
              <Skeleton className="h-6 w-40" />
              <Skeleton className="h-6 w-96" />
            </div>
          ) : (
            <div className="text-center">
              {this.state.orderId ? (
                this.state.paymentStatus === "TXN_SUCCESS" ? (
                  <div>
                    <h3 className="capitalize text-2xl">Payment Successful</h3>
                    <p>
                      Thanks for making the payment. Your account has been
                      successfully updated.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h3 className="capitalize text-2xl">Payment Failed</h3>
                    <p>
                      {this.state.paymentFailureReason ||
                        "Your payment could not be processed. Please try again."}
                    </p>
                  </div>
                )
              ) : (
                <div>
                  <h3 className="capitalize text-2xl">No payment found</h3>
                  <p>Please return to the subscriptions page and try again.</p>
                </div>
              )}
            </div>
          )}
          {/* <div className="flex justify-center">
            {this.state.paymentStatus === "TXN_SUCCESS" ? (
              <Link
                to={`/${this.props.currentFirm?._id}/clients/list`}
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Go back home
              </Link>
            ) : (
              <Link
                to={`/${this.props.currentFirm?._id}/subscriptions`}
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Go to subscriptions page
              </Link>
            )}
          </div> */}
        </div>
      </Dashboard>
    );
  }
}

export default compose<ComponentType>(connector, withRouter)(PaymentReponse);
