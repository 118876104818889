import { Component, ComponentType } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard, {
  checkIfPlanExpired,
  handleFirmChange,
} from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
import "../style.css";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { compose } from "redux";
//Modals for the firm page
import AddEditFirm from "./AddEdit";
import DeleteModal from "../../components/DeleteModal";
import InvitationModal, {
  InvitationModalType,
} from "../../components/InvitationModal";
//Router
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
//Tag manager
import TagManager from "react-gtm-module";
import Popup from "../../components/Popup";
import { formatDate } from "../../helpers/formatDate";
import { UserInvitation, WorkSpace } from "../../helpers/types";
import { RootState, AppDispatch } from "../../store";
import { CommonState } from "../../store/reducers/common";
import { NotificationType } from "../../store/reducers/notification";
import Actions, { ActionsOption } from "../../components/Actions";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "Finexo Login Tool",
    page: "Firms List",
  },
  dataLayerName: "PageDataLayer",
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common,
  ...state.notification,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {};

type State = {
  firmLoading: boolean;
  posX: number;
  posY: number;
  showBackDrop: boolean;
  firmDetails: WorkSpace[];
  totalRecords: number;
  showAddEditModal: boolean;
  modalType?: "add" | "edit" | "";
  selectedRow?: any;
  sentLoading: boolean;
  invitationSentDetails: UserInvitation[];
  showActiveModal: boolean;
  showInActiveModal: boolean;
  showDeleteModal: boolean;
  recieveLoading: boolean;
  invitationRecieveDetails: UserInvitation[];
  showInvitationModal: boolean;
  invitationHeading: string;
  invitationType: InvitationModalType | "";
};

class Firms extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      firmLoading: false,
      posX: 0,
      posY: 0,
      showBackDrop: false,
      firmDetails: [],
      totalRecords: 0,
      showAddEditModal: false,
      modalType: "",
      selectedRow: undefined,
      sentLoading: false,
      invitationSentDetails: [],
      showActiveModal: false,
      showInActiveModal: false,
      showDeleteModal: false,
      recieveLoading: false,
      invitationRecieveDetails: [],
      showInvitationModal: false,
      invitationHeading: "",
      invitationType: "",
    };
  }

  firmListHeaders = [
    "FIRM NAME",
    "OWNER'S EMAIL",
    "PLAN",
    "STATUS",
    "EXPIRY DATE",
    "ACTIONS",
  ];

  firmInvitationHeaders = [
    "FIRM NAME",
    "SEND BY",
    "USER ACCESS RIGHT",
    "STATUS",
    "ACTIONS",
  ];

  firmInvitationSentHeaders = [
    "FIRM NAME",
    "SEND TO",
    "USER ACCESS RIGHT",
    "STATUS",
    "ACTIONS",
  ];

  //Get Firm Data
  getFirmData = () => {
    this.setState({ firmLoading: true });
    agent.Firm.getFirms<{ workspaces: WorkSpace[] }>()
      .then((response) => {
        this.setState({
          firmDetails: response.workspaces,
          firmLoading: false,
          totalRecords: response.workspaces.length,
        });

        //filtering the active firms
        const activeFirms = response.workspaces.filter((firm) => firm.active);
        //updating the active firms in redux
        this.props.updateCommon?.({
          firms: activeFirms,
          isFirmPresent: activeFirms.length > 0,
        });
      })
      .catch((err) => {
        this.setState({ firmLoading: false });
        this.props.addNotification?.(
          "Could not load Firm Details",
          err?.response?.data?.message ||
            err?.response?.data?.error ||
            err?.message ||
            err,
          "danger",
        );
      });
  };

  getInvitaionSentList = () => {
    const workSpaceId = this.props?.currentFirm?._id;
    if (workSpaceId) {
      this.setState({ sentLoading: true });
      agent.Firm.listofInvitationSent<{ invitations: UserInvitation[] }>(
        workSpaceId,
      )
        .then((response) => {
          this.setState({
            invitationSentDetails: response.invitations,
            sentLoading: false,
          });
        })
        .catch((err) => {
          this.setState({ sentLoading: false });
          this.props.addNotification?.(
            "Could not load Sent Invitaion Lists",
            err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            "danger",
          );
        });
    } else {
      this.setState({ invitationSentDetails: [] });
    }
  };

  getInvitaionRecievedList = () => {
    this.setState({ recieveLoading: true });
    agent.Firm.listofInvitationReceived<{ invitations: UserInvitation[] }>()
      .then((response) => {
        this.setState({
          invitationRecieveDetails: response.invitations,
          recieveLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ recieveLoading: false });
        this.props.addNotification?.(
          "Could not load Received Invitaion Lists",
          err?.response?.data?.message ||
            err?.response?.data?.error ||
            err?.message ||
            err,
          "danger",
        );
      });
  };

  // onMount Load data
  componentDidMount() {
    document.title = "Firms - Finexo Login Tool";
    this.getFirmData();
    this.getInvitaionSentList();
    this.getInvitaionRecievedList();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId = prevProps.currentFirm?._id;
    const currFirmId = this.props.currentFirm?._id;

    //RP Pending - To fetch the invitation sent list when user do not have any firm in his accounts and create a new firm and send invitation (not sure)
    if (prevFirmId === undefined && currFirmId) {
      this.getInvitaionSentList();
    }

    // To fetch the invitation sent list when the firm is changed
    if (prevFirmId !== currFirmId) {
      this.getInvitaionSentList();
    }
  }

  showHideAddEditModal = (open: boolean) => {
    this.setState({ showAddEditModal: open });
  };

  openAddEditModal = (modalType: "add" | "edit", firm?: WorkSpace) => {
    this.setState({ selectedRow: firm, modalType, showBackDrop: false });
    this.showHideAddEditModal(true);
  };

  openDeleteModal = (firm: WorkSpace) => {
    this.setState({ selectedRow: firm, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({ showDeleteModal: open });
  };

  openActiveModal = (firm: WorkSpace) => {
    this.setState({ selectedRow: firm, showBackDrop: false });
    this.activeModalSetOpen(true);
  };

  activeModalSetOpen = (open: boolean) => {
    this.setState({ showActiveModal: open });
  };

  openInActiveModal = (firm: WorkSpace) => {
    this.setState({ selectedRow: firm, showBackDrop: false });
    this.inActiveModalSetOpen(true);
  };

  inActiveModalSetOpen = (open: boolean) => {
    this.setState({ showInActiveModal: open });
  };

  openRevokeModal = (item: UserInvitation) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false,
      invitationHeading: "Revoke Invitation",
      invitationType: "Revoke",
    });
    this.invitationModalSetOpen(true);
  };

  openRejectModal = (item: UserInvitation) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false,
      invitationHeading: "Reject Invitation",
      invitationType: "Reject",
    });
    this.invitationModalSetOpen(true);
  };

  openAcceptModal = (item: UserInvitation) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false,
      invitationHeading: "Accept Invitation",
      invitationType: "Accept",
    });
    this.invitationModalSetOpen(true);
  };

  openLeaveFirmModal = (item: WorkSpace) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false,
      invitationHeading: "Leave Firm",
      invitationType: "Leave",
    });
    this.invitationModalSetOpen(true);
  };

  invitationModalSetOpen = (open: boolean) => {
    this.setState({ showInvitationModal: open });
  };

  onInvitationLoad = () => {
    const type = this.state.invitationType;
    if (type === "Revoke") {
      this.getInvitaionSentList();
    } else if (type === "Reject") {
      this.getInvitaionRecievedList();
    } else if (type === "Accept") {
      this.getInvitaionRecievedList();
      this.getFirmData();
    } else if (type === "Leave") {
      this.getFirmData();
    }
  };

  changeFirm = (firm: WorkSpace) => {
    const currentFirm = this.props.currentFirm;
    if (currentFirm?._id === firm._id) {
      return this.props.navigate?.(`/${firm._id}/subscriptions`);
    }

    handleFirmChange(
      firm,
      false,
      this.props.updateCommon,
      this.props.addNotification,
    ).then(() => {
      this.props.navigate?.(`/${firm._id}/subscriptions`);
    });
  };

  firmOptions = (firm: WorkSpace): ActionsOption[] => {
    const ownerOptions: ActionsOption[] = [
      {
        name: "Edit",
        icon: "edit",
        action: () => this.openAddEditModal("edit", firm),
      },
      {
        name: "Delete",
        icon: "delete",
        action: () => this.openDeleteModal(firm),
      },
    ];

    const userOptions: ActionsOption[] = [
      {
        name: "Leave Firm",
        icon: "outline/logout",
        action: () => this.openLeaveFirmModal(firm),
      },
    ];

    return [
      ...(firm.isOwner ? ownerOptions : userOptions),
      {
        name: "Subscription",
        icon: "outline/card",
        action: () => this.props.navigate?.(`/${firm._id}/subscriptions`),
      },
    ];
  };

  invitationRecieveOptions = (invitation: UserInvitation): ActionsOption[] => [
    {
      name: "Accept",
      icon: "signin",
      action: () => this.openAcceptModal(invitation),
    },
    {
      name: "Reject",
      icon: "x-circle",
      action: () => this.openRejectModal(invitation),
    },
  ];

  invitationSentOptions = (invitation: UserInvitation): ActionsOption[] => [
    {
      name: "Revoke",
      icon: "outline/logout",
      action: () => this.openRevokeModal(invitation),
    },
  ];

  render() {
    TagManager.dataLayer(tagManagerArgs);

    const { selectedRow, showDeleteModal, showInvitationModal } = this.state;

    return (
      <Dashboard>
        <div className="mb-8 space-y-10">
          {this.state.showAddEditModal && this.state.modalType && (
            <AddEditFirm
              type={this.state.modalType}
              showModal={this.state.showAddEditModal}
              firm={this.state.selectedRow}
              onLoad={this.getFirmData}
              openCloseModal={this.showHideAddEditModal}
            />
          )}

          {this.state.showDeleteModal && (
            <DeleteModal
              type={"firm"}
              state={{ selectedRow, showDeleteModal }}
              onLoad={this.getFirmData}
              deleteModalSetOpen={this.deleteModalSetOpen}
            />
          )}

          {showInvitationModal && this.state.invitationType && (
            <InvitationModal
              heading={this.state.invitationHeading}
              type={this.state.invitationType}
              state={{ selectedRow, showInvitationModal }}
              onLoad={this.onInvitationLoad}
              invitationModalSetOpen={this.invitationModalSetOpen}
            />
          )}

          <div className="max-w-7xl mx-auto flex justify-between items-center lg:mx-8">
            <h1 className="text-2xl font-semibold text-gray-900">Firms</h1>
            <div>
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                onClick={() => this.openAddEditModal("add")}
              >
                <Icon name="add" className="h-4 w-4 mr-2" />
                Add Firms
              </button>
            </div>
          </div>

          {/* Firm List */}
          <div className="max-w-7xl mx-auto lg:mx-8 space-y-6">
            {/* Firm List Cards */}
            <div className="sm:hidden px-2">
              {!this.state.firmLoading ? (
                this.state.totalRecords > 0 ? (
                  <ul className="space-y-4">
                    {this.state.firmDetails.map((firm, index) => (
                      <li
                        key={firm._id}
                        className="text-sm bg-white shadow-md rounded-lg"
                      >
                        <div className="px-4 py-4 relative space-y-2">
                          <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute top-0 -translate-y-1/2 -right-4 shadow uppercase">
                            {/* {firm.active ? "Active" : "Inactive"} */}
                            {checkIfPlanExpired(firm) ? "Expired" : "Active"}
                          </span>
                          <div className="flex justify-between">
                            <p className="text-gray-800 font-semibold flex-grow">
                              <span className="font-bold">Name - </span>
                              {firm.isOwner ? (
                                <button
                                  className="hover:underline"
                                  onClick={() =>
                                    this.openAddEditModal("edit", firm)
                                  }
                                >
                                  {firm.name}
                                </button>
                              ) : (
                                <span>{firm.name}</span>
                              )}
                            </p>
                            <div className="justify-self-end">
                              <Actions
                                responsive
                                options={this.firmOptions(firm)}
                              />
                            </div>
                          </div>
                          <p>
                            <span className="font-bold">Plan - </span>
                            <span className="capitalize text-indigo-600">
                              {firm?.type ? firm?.type : "-"}
                            </span>
                          </p>
                          <p>
                            <span className="font-bold">Email - </span>
                            <span className="break-words">{firm.owner}</span>
                          </p>
                          <p>
                            <span className="font-bold">Expiry Date - </span>
                            <span className="break-words">
                              {formatDate(
                                firm?.planExpiryDate || firm?.trialExpiryDate,
                                false,
                              ) || "-"}
                            </span>
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="bg-white shadow-md rounded-lg">
                    <div className="px-4 py-4">
                      <p className="text-sm text-gray-800 text-center font-semibold">
                        No Firm
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div
                      key={i}
                      className="bg-white shadow-md rounded-lg space-y-4"
                    >
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 items-center relative`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(3)].map((e, i) => (
                          <Skeleton
                            key={`firm-list-skeleton-${i}`}
                            height="1rem"
                            className="my-2"
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* Firm List Table */}
            <div
              id="table-scroll"
              className="hidden sm:block max-h-screen overflow-auto vertical-scroll"
            >
              <div className="inline-block min-w-full py-2 px-1 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-collapse border shadow-sm">
                    <thead className="bg-gray-50">
                      <tr>
                        {this.firmListHeaders.map((header, index) => (
                          <th
                            key={header}
                            scope="col"
                            className={`sticky top-0 z-[8] border-b whitespace-nowrap font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider ${
                              index === 0 && "w-3/12 px-6"
                            } ${
                              index === this.firmListHeaders.length - 1
                                ? "text-center"
                                : "text-left"
                            }`}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {!this.state.firmLoading ? (
                        this.state.totalRecords === 0 ? (
                          <tr>
                            <td
                              colSpan={this.firmListHeaders.length}
                              className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              No Firm
                            </td>
                          </tr>
                        ) : (
                          this.state.firmDetails?.map((firm, index) => (
                            <tr
                              key={firm._id}
                              className={
                                index % 2 === 0 ? undefined : "bg-gray-100"
                              }
                            >
                              <td className="max-w-[20ch] whitespace-nowrap py-4 px-6 font-bold text-sm text-gray-900">
                                <Popup
                                  content={firm.name}
                                  className="w-full max-w-fit"
                                >
                                  {firm.isOwner ? (
                                    <p
                                      className="hover:underline font-bold truncate"
                                      onClick={() =>
                                        this.openAddEditModal("edit", firm)
                                      }
                                    >
                                      {firm.name}
                                    </p>
                                  ) : (
                                    <p className="truncate">{firm.name}</p>
                                  )}
                                </Popup>
                              </td>
                              <td className="max-w-[22ch] px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                <Popup
                                  content={firm.owner}
                                  className="w-full max-w-fit"
                                >
                                  <p className="truncate">{firm.owner}</p>
                                </Popup>
                              </td>
                              <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm font-bold text-gray-900">
                                {firm.type.toUpperCase()}
                              </td>
                              {/* <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                {firm.active ? "Active" : "Inactive"}
                              </td> */}
                              <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                {checkIfPlanExpired(firm)
                                  ? "Expired"
                                  : "Active"}
                              </td>
                              <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                {formatDate(
                                  firm?.planExpiryDate || firm?.trialExpiryDate,
                                  false,
                                ) || "-"}
                              </td>
                              <td className="w-2/10 px-4 py-3 text-center whitespace-nowrap text-sm text-gray-900">
                                <Actions options={this.firmOptions(firm)} />
                              </td>
                            </tr>
                          ))
                        )
                      ) : (
                        [...Array(5)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[...Array(this.firmListHeaders.length)].map(
                              (e, i) => (
                                <td
                                  key={i}
                                  className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ),
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/*   Firm Invitation Received */}
          <div className="max-w-7xl mx-auto lg:mx-8 space-y-6">
            <h1 className="text-2xl font-semibold text-gray-900">
              Firm Invitation Received
            </h1>
            {/*  Firm Invitation Received Cards*/}
            <div className="sm:hidden px-2">
              {!this.state.recieveLoading ? (
                this.state.invitationRecieveDetails.length > 0 ? (
                  <ul className="space-y-4">
                    {this.state.invitationRecieveDetails.map(
                      (invitation, index) => (
                        <li
                          key={invitation._id}
                          className="text-sm bg-white shadow-md rounded-lg"
                        >
                          <div className="px-4 py-4 relative space-y-2">
                            <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute top-0 -translate-y-1/2 -right-4 shadow uppercase">
                              {invitation.status}
                            </span>
                            <div className="flex justify-between">
                              <p className="text-sm text-gray-800 font-semibold">
                                <span className="font-bold">Name - </span>
                                <span>{invitation.workSpaceId.name}</span>
                              </p>
                              <div className="justify-self-end">
                                <Actions
                                  responsive
                                  options={this.invitationRecieveOptions(
                                    invitation,
                                  )}
                                />
                              </div>
                            </div>
                            <p>
                              <span className="font-bold">Role - </span>
                              <span className="capitalize text-indigo-600">
                                {invitation.userAccessRole}
                              </span>
                            </p>
                            <p>
                              <span className="font-bold">Sent by - </span>
                              <span className="break-words">
                                {invitation.sentBy}
                              </span>
                            </p>
                          </div>
                        </li>
                      ),
                    )}
                  </ul>
                ) : (
                  <div className="bg-white shadow-md rounded-lg">
                    <div className="px-4 py-4">
                      <p className="text-sm text-gray-800 text-center font-semibold">
                        No Firms found
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div
                      key={i}
                      className="bg-white shadow-md rounded-lg space-y-4"
                    >
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 items-center relative`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(3)].map((e, i) => (
                          <Skeleton
                            key={`firm-list-skeleton-${i}`}
                            height="1rem"
                            className="my-2"
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/*  Firm Invitation Received Table*/}
            <div
              id="table-scroll"
              className="hidden sm:block max-h-screen overflow-auto vertical-scroll"
            >
              <div className="inline-block min-w-full py-2 px-1 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-collapse border shadow-sm">
                    <thead className="bg-gray-50">
                      <tr>
                        {this.firmInvitationHeaders.map((header, index) => (
                          <th
                            key={header}
                            scope="col"
                            className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider ${
                              index === 0 && "w-3/12 px-6"
                            } ${
                              index === this.firmInvitationHeaders.length - 1
                                ? "text-center"
                                : "text-left"
                            }`}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {!this.state.recieveLoading ? (
                        this.state.invitationRecieveDetails.length === 0 ? (
                          <tr>
                            <td
                              colSpan={this.firmInvitationHeaders.length}
                              className="pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              No Invitations Recieved
                            </td>
                          </tr>
                        ) : (
                          this.state.invitationRecieveDetails?.map(
                            (invitation, index) => (
                              <tr
                                key={invitation._id}
                                className={
                                  index % 2 === 0 ? undefined : "bg-gray-100"
                                }
                              >
                                <td className="max-w-[20ch] whitespace-nowrap py-4 px-4 font-bold text-sm text-gray-900">
                                  <Popup
                                    content={invitation.workSpaceId.name}
                                    className="w-full max-w-fit"
                                  >
                                    <p className="truncate">
                                      {invitation.workSpaceId.name}
                                    </p>
                                  </Popup>
                                </td>
                                <td className="max-w-[20ch] px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                  <Popup
                                    content={invitation.sentBy}
                                    className="w-full max-w-fit"
                                  >
                                    <p className="truncate">
                                      {invitation.sentBy}
                                    </p>
                                  </Popup>
                                </td>
                                <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm font-bold text-gray-900 capitalize">
                                  {invitation.userAccessRole}
                                </td>
                                <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                  {invitation.status}
                                </td>
                                <td className="w-2/10 px-4 py-3 text-center whitespace-nowrap text-sm text-gray-900">
                                  <Actions
                                    options={this.invitationRecieveOptions(
                                      invitation,
                                    )}
                                  />
                                </td>
                              </tr>
                            ),
                          )
                        )
                      ) : (
                        [...Array(6)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[...Array(this.firmInvitationHeaders.length)].map(
                              (e, i) => (
                                <td
                                  key={i}
                                  className="w-3/12 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ),
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* User Invitation Sent */}
          <div className="max-w-7xl mx-auto lg:mx-8 space-y-6">
            <h1 className="text-2xl font-semibold text-gray-900">
              User Invitation Sent
            </h1>
            {/* User Invitation Sent Cards*/}
            <div className="sm:hidden px-2">
              {!this.state.sentLoading ? (
                this.state.invitationSentDetails.length > 0 ? (
                  <ul className="space-y-4">
                    {this.state.invitationSentDetails.map(
                      (invitation, index) => (
                        <li
                          key={invitation._id}
                          className="text-sm bg-white shadow-md rounded-lg"
                        >
                          <div className="px-4 py-4 relative space-y-2">
                            <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute top-0 -translate-y-1/2 -right-4 shadow uppercase">
                              {invitation.status}
                            </span>
                            <div className="flex justify-between">
                              <p className="text-sm text-gray-800 font-semibold">
                                <span className="font-bold">Name - </span>
                                <span>{invitation.workSpaceId.name}</span>
                              </p>
                              <div className="justify-self-end">
                                <Actions
                                  responsive
                                  options={this.invitationSentOptions(
                                    invitation,
                                  )}
                                />
                              </div>
                            </div>
                            <p>
                              <span className="font-bold">Role - </span>
                              <span className="capitalize text-indigo-600">
                                {invitation.userAccessRole}
                              </span>
                            </p>
                            <p>
                              <span className="font-bold">Sent to - </span>
                              <span className="break-words">
                                {invitation.email}
                              </span>
                            </p>
                          </div>
                        </li>
                      ),
                    )}
                  </ul>
                ) : (
                  <div className="bg-white shadow-md rounded-lg">
                    <div className="px-4 py-4">
                      <p className="text-sm text-gray-800 text-center font-semibold">
                        No Invitations Sent
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div
                      key={i}
                      className="bg-white shadow-md rounded-lg space-y-4"
                    >
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 items-center relative`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(3)].map((e, i) => (
                          <Skeleton
                            key={`firm-list-skeleton-${i}`}
                            height="1rem"
                            className="my-2"
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* User Invitation Sent Table*/}
            <div
              id="table-scroll"
              className="hidden sm:block max-h-screen overflow-auto vertical-scroll"
            >
              <div className="inline-block min-w-full py-2 px-1 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-collapse border shadow-sm">
                    <thead className="bg-gray-50">
                      <tr>
                        {this.firmInvitationSentHeaders.map((header, index) => (
                          <th
                            key={header}
                            scope="col"
                            className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider ${
                              index === 0 && "w-3/12 px-6"
                            } ${
                              index ===
                              this.firmInvitationSentHeaders.length - 1
                                ? "text-center"
                                : "text-left"
                            }`}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {!this.state.sentLoading ? (
                        this.state.invitationSentDetails.length === 0 ? (
                          <tr>
                            <td
                              colSpan={this.firmInvitationSentHeaders.length}
                              className="pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              <div className="w-fit m-0">
                                No Invitations Sent
                                <span className="invisible">hide</span>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          this.state.invitationSentDetails?.map(
                            (invitation, index) => (
                              <tr
                                key={invitation._id}
                                className={
                                  index % 2 === 0 ? undefined : "bg-gray-100"
                                }
                              >
                                <td className="max-w-[20ch] whitespace-nowrap py-4 px-6 font-bold text-sm text-gray-900">
                                  <Popup
                                    content={invitation.workSpaceId.name}
                                    className="w-full max-w-fit"
                                  >
                                    <p className="truncate">
                                      {invitation.workSpaceId.name}
                                    </p>
                                  </Popup>
                                </td>
                                <td className="max-w-[20ch] px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                  <Popup
                                    content={invitation.email}
                                    className="w-full max-w-fit"
                                  >
                                    <p className="truncate">
                                      {invitation.email}
                                    </p>
                                  </Popup>
                                </td>
                                <td className="max-w-min px-4 py-3 whitespace-nowrap text-sm font-bold text-gray-900 capitalize">
                                  {invitation.userAccessRole}
                                </td>
                                <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                  {invitation.status}
                                </td>
                                <td className="w-2/10 px-4 py-3 text-center whitespace-nowrap text-sm text-gray-900">
                                  <Actions
                                    options={this.invitationSentOptions(
                                      invitation,
                                    )}
                                  />
                                </td>
                              </tr>
                            ),
                          )
                        )
                      ) : (
                        [...Array(5)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[
                              ...Array(this.firmInvitationSentHeaders.length),
                            ].map((e, i) => (
                              <td
                                key={i}
                                className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                              >
                                <Skeleton />
                              </td>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose<ComponentType<Props>>(connector, withRouter)(Firms);
