export const validName = new RegExp("^[a-zA-Z\\s\\d]{5,20}$");

// export const validEmail = new RegExp(
//   "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
// );

export const validEmail = new RegExp(
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i
);

// r'^
//   (?=.*[A-Z])       // should contain at least one upper case
//   (?=.*[a-z])       // should contain at least one lower case
//   (?=.*?[0-9])      // should contain at least one digit
//   (?=.*?[!@#\$&*~]) // should contain at least one Special character
//   .{8,}             // Must be at least 8 characters in length
// $
export const validPassword = new RegExp(
  "^(?=.*[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[!@#$&*~.-]).{8,}$"
);

export const validSymbols = new RegExp("^(?=.*?[!@#$&*~.-])");

export const validNumbers = new RegExp("^(?=.*?[0-9])");

// export const validPAN = new RegExp(
//   "^[A-Z]{4}[P,C,H,A,B,G,J,L,F,T][0-9]{4}[A-Z]{1}"
// );

// export const validPAN = new RegExp("^[A-Z]{5}[0-9]{4}[A-Z]{1}");

export const validPAN = /^[A-Z]{3}[ABCFGHLJPT][A-Z]\d{4}[A-Z]$/;
export const validTAN = /^[A-Z]{4}\d{5}[A-Z]$/;

export const validMobile = /^[6-9]\d{9}$/;
/**
 * Validates a value against a regular expression.
 * @param regex - The regular expression to match against.
 * @param valueToCheck - The value to be validated.
 * @param required - Indicates if the value is required. Default is false.
 * @returns True if the value is valid, false otherwise.
 */

export function validate(
  regex: RegExp,
  valueToCheck: string,
  required = false
) {
  return required
    ? regex.test(valueToCheck)
    : valueToCheck === "" || regex.test(valueToCheck);
}
