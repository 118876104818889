import { Dialog, Transition } from "@headlessui/react";
import React, { ChangeEvent, Component, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import Icon from "../../components/Icon";
import agent from "../../agent";
import capitalize from "../../helpers/capitalize";
import { WithRouterProps } from "../../helpers/withRouter";
import { RootState, AppDispatch } from "../../store";
import { CommonState } from "../../store/reducers/common";
import { NotificationType } from "../../store/reducers/notification";
import { WorkSpace } from "../../helpers/types";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common,
  ...state.notification
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotificationType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {
  type: "add" | "edit";
  firm?: WorkSpace;
  showModal?: boolean; //to show the modal
  onLoad?: () => void; //to reload the listing of firm on the firm listing page
  openCloseModal?: (open: boolean) => void; //to set the modal open or close
  closeModal?: (fetchAgain: boolean) => void; //to close the modal from the App.tsx only when there is no firm
};

type State = {
  loading: boolean;
  name: string;
};

class AddEditFirm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      name: props.firm?.name || ""
    };
  }

  onKeyUpFunction = (event: KeyboardEventInit) => {
    if (event.key === "Escape") {
      this.setOpen(false);
    }

    if (event.key === "Enter") {
      this.addEditFirm();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  addEditFirm = () => {
    const name = this.state.name;
    const workSpaceId = this.props.firm?._id;

    if (!workSpaceId && this.props.type === "edit") return;

    if (!name) {
      return this.props.addNotification?.(
        "Firm Name Required",
        "Please enter your firm name.",
        "danger"
      );
    }

    this.setState({ loading: true });

    const API = this.props.type === "add" ? "addFirm" : "editFirm";

    agent.Firm[API](name, workSpaceId || "")
      .then(response => {
        this.setState({ name: "" });
        this.props.addNotification?.(
          `Firm ${
            this.props.type === "add" ? "Added" : "Updated"
          } Successfully`,
          "New firm has been added successfully.",
          "success"
        );
        this.props.onLoad?.();
        this.setOpen(false);

        if (this.props.type === "edit") {
          //to update the name of the current firm if any changes made by the user and updating the store
          const currentSelectedFirm = this.props.currentFirm;
          if (currentSelectedFirm && currentSelectedFirm?._id === workSpaceId) {
            this.props.updateCommon?.({
              currentFirm: { ...currentSelectedFirm, name } //updating the name of the firm
            });
          }
        }
      })
      .catch((err: any) => {
        this.props.addNotification?.(
          `Could not ${this.props.type} firm`,
          err?.response?.data?.message ||
            err?.response?.data?.error ||
            err?.message ||
            err,
          "danger"
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  setOpen = (open: boolean) => {
    if (this.props?.currentModal?.modalName === "ADD_FIRM_MODAL") {
      this.props.closeModal?.(open);
    } else {
      this.props.openCloseModal?.(open);
    }
  };

  handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: capitalize(ev.target.value) });
  };

  render() {
    return (
      <Transition.Root
        show={
          this.props?.currentModal?.modalName === "ADD_FIRM_MODAL" ||
          this.props?.showModal
        }
        as={Fragment}
      >
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => null}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg mx-6 px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
                <div>
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900 capitalize">
                      {this.props.type} Firm
                    </h3>
                  </div>
                  <div>
                    <form onSubmit={e => e.preventDefault()}>
                      <div className="mt-4">
                        <div className="mb-4">
                          <label
                            htmlFor="add_firm"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Firm Name <span className="text-red-600">*</span>
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="add_firm"
                              value={this.state.name}
                              onChange={this.handleNameChange}
                              id="add_firm"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              placeholder="Enter your firm name"
                            />
                          </div>
                        </div>
                        {this.props.type === "add" &&
                          this.props.firms?.filter((firm: any) => firm.active)
                            .length === 0 && (
                            <div className="text-red-600 mt-2 text-sm font-medium">
                              Note: You do not have any active firm in your
                              account. In order to use the application first add
                              a firm to your account.
                            </div>
                          )}
                      </div>

                      <div className="mt-5 sm:mt-4 flex gap-4 items-center justify-end">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none w-32 sm:text-sm"
                          onClick={() => this.setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          disabled={this.state.loading}
                          className={
                            "inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 w-32 sm:text-sm"
                          }
                          onClick={this.addEditFirm}
                        >
                          <span className="w-full flex justify-end">
                            {this.state.loading ? (
                              <Icon name="loading" className="mr-2" />
                            ) : null}
                          </span>
                          <span>
                            {this.props.type === "add" ? "Save" : "Update"}
                          </span>
                          <span className="w-full"></span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

export default connector(AddEditFirm);
